import React from 'react';
import {bindActionCreators} from "redux";
import {tokenAction} from "../redux-stuffs/actions/token_action";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Header from "../components/Header";

class FAQ extends React.Component {

    render() {
        return (
            <div>
                <Header/>
                <div className="container mt-4">
                    <div className="spare-dvs vh-100 align-items-start">
                    <div className="w-100 float-left">
                    <h1>FAQ</h1>
                    <br></br>
                    <Accordion title="How does Spare work?">
                    <ol style={{listStylePosition: "inside", textAlign: "left"}}>
                            <li>Add cash to your account from credit, debit, or check.
                            </li>
                            <br></br>
                            <li>Enter in how much cash you want to withdraw.
                            </li>
                            <br></br>
                            <li>Browse nearby locations and submit a request for your cash.
                            </li>
                            <br></br>
                            <li>Show the merchant your one-time code and get your cash.
                            </li>
                            <br></br>
                            <li>Bookmark SPARE for easy access to cash whenever, wherever!
                            </li>
                      </ol>
                    </Accordion>
                    <Accordion title="Can anyone use this app?">
                      Anyone over the age of 18 is permitted to use this application.
                    </Accordion>
                    <Accordion title="How many different digital wallets does SPARE accept?">
                    SPARE strives to import as many digital wallets and currencies into its platform as possible. Currently, we support Apple Pay, Google Pay, Paypal, and uploads from your Visa or Mastercard.
                    </Accordion>
                    <Accordion title="Can I send promo funds to my bank?">
                    We do not allow users to cash out promo funds to their bank account. The funds are designed to be used inside of the network with either other users or with our business partners.
                    </Accordion>
                    <Accordion title="How do I send funds to my bank?">
                    "As long as you have a minimum balance of $50 you can send us a request via the customer care chat with:

                    The amount you want to withdraw, name on the bank account, routing number and account number.

                    *We reserve the right to restrict bank transfers if they are solely for promotional funds that should be used within the network."
                    </Accordion>
                    <Accordion title="What is the 'zero tolerance' policy?">
                    Any behavior that indicates fraud, the illusion of fraud, illegal action, misuse of funds, failure to properly identify, gaming the promotional system, or any activity the company deems to be unsavory to the network of users and/or business partners, will result in account suspension and all ill-gotten funds will be removed.
                    </Accordion>
                    <Accordion title="My account was suspended - what should I do?">
                    "Feel free to reach out to our customer service. It could be a simple issue to fix (ID verification, missing contact info, etc.).

                    Once an account is suspended the company reserves the right to not allow that account to resume operation if it falls under the provisions of the ""zero tolerance policy""."
                    </Accordion>
                    <Accordion title="How can I open SPARE on my phone, Laptop, Computer?">
                    "Download the app or use the website.

                    Apple: https://apps.apple.com/us/app/spare/id979959635

                    Android: https://play.google.com/store/apps/details?id=com.sparecs.spare&hl=en_IE&gl=US

                    Web: web.gotspare.com"
                    </Accordion>
                    <Accordion title="SPARE shows me that my session has expired what do I do?">
                    Restart the app or your web browser.
                    </Accordion>
                    <Accordion title="What are the Fees for using SPARE as an ATM?">
                    Fees range from free up to 5%.
                    </Accordion>
                    <Accordion title="Will the Customers and Merchants receive a receipt for the transaction?">
                    Yes, each gets an email notification as well as access to the transaction history in their account via the app or web.
                    </Accordion>
                    <Accordion title="Can Customers withdraw cash from any merchant that offers SPARE?">
                    Most of SPAREs merchants offer "Cash-out", however, we have some that limit their services to "Cash-in", "Check Cashing", and "Loans". If you need help telling the difference - use the sort button in the app.
                    </Accordion>
                    <Accordion title="Is there a withdrawal limit on SPARE?">
                    Yes, there are limits based on your security tier level.
                    </Accordion>
                    <Accordion title="Why is SPAREs 'zero tolerance' policy so strict?">
                    Trust. We have worked hard to build our network of users and small businesses. We have seen many different forms of fraud that degrade the integrity of our network. In addition, we are a small company and if we were held responsible (as a company) for every transaction that ended in fraud in our network - we would not have a company after a very short period of time.
                    </Accordion>
                    <Accordion title="Why does my check take so long to clear?">
                    Your first check will not clear until we have received the funds from your bank, which is typically 24-72 hours after submission. It is not until you have processed your 4th check (and the previous 3 have cleared without issue) that you may be eligible for instant access on check deposits.
                    </Accordion>
                    <Accordion title="How do I get paid to pick up cash?">
                    Once you have registered in the app, find all of the locations with the ($) on the app. Each time you request cash from these locations you will make an extra cash bonus; up to $50 per pick-up.
                    </Accordion>
                    <Accordion title="If you send money to someone who doesn't have an account yet, what happens?">
                    "If this happens, we will debit your account of the amount you are intending on sending. We will send them a text message letting them know that cash is available for them. Once they activate and verify their account, the money you sent will be available for them.

                      If there are any issues, you can contact customer service by:

                      E-mail: support@gotspare.com
                      Phone (call or text): (424) 744-0155"
                    </Accordion>
                    <Accordion title="What is required to deposit funds to my bank account?">
                    "Customers do have the ability to deposit to their bank as long as they meet the following requirements:

                      - completed account verification

                      - provide banking information

                      - balance above $50

                      - promotion credits will not be counted toward the amount available for deposit request."
                    </Accordion>
                    <Accordion title="What is the maximum deposit and reward that I can earn?">
                    The maximum deposit that can be made per month is $10,000. Which can be paid from a credit, debit and crypto payment source. The max deposit that can be earned in a month is 10%. Please check the app for the specific reward payout once your deposit is made as the reward amount varies.
                    </Accordion>
                    <Accordion title="How can I contact customer service?">
                    "You can contact customer service by:

                      E-mail: support@gotspare.com
                      Phone (call or text): (424) 744-0155"
                    </Accordion>
                    <Accordion title="My check deposit was denied - what should I do now?">
                    "It is unfortunate that your check deposit was denied. This can happened for several reasons:
                      - your account has not completed verification with SPARE
                      - your account is locked or flagged for suspicious activity
                      - the check you submitted does not match your account information
                      - the check information is invalid or can't be verified
                      - the check was submitted in a different name other than the account holder
                      - the check in not readable

                      In the event of any of the above you can:
                      - resubmit your check for a second review
                      - contact customer service via email: support@gotspare.com
                      - contact us via text or call at (424) 744-0155"
                    </Accordion>
                    <Accordion title="I just went to a location and they have no idea who you are - what do I do?">
                    From time to time we will have a location that was added into our system that has not yet completed verification or trained of the local staff. If you come across one of the locations, please contact us immediately and provide the location name, city, and state. We do our best to make sure that we maintain a safe, secure, and active merchant base and greatly appreciate the feedback if a location is not meeting its obligation to the network.
                    </Accordion>
                    <Accordion title="I came across a location that looks like it's someone's home or a non-commercial/business location.">
                    If this happens DO NOT VISIT THIS LOCATION and contact out team immediately with the location name, city, and state. We want to maintain a safe and secure network and residential locations are not permitted in SPAREs network. From time to time we may miss a new location through verification and a residential location becomes active. Please help us by reporting these locations when you see them.
                    </Accordion>
                    <Accordion title="Why am I unable to send or request money?">
                    There may be an issue with your account that has flagged the account for review. Please contact customer service via email, or call or text at (424) 744-0155.
                    </Accordion>
                    <Accordion title="I already have a SPARE account, what do I have to do?">
                    Submit your government issued ID for verification, fund your SPARE wallet, and you are ready to go.
                    </Accordion>
                    <br></br>
                    </div>
                    </div>
                </div>
            </div>
        );
    };
}

class Accordion extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isOpen: false
      };
      this.toggleAccordion = this.toggleAccordion.bind(this);
    }
  
    toggleAccordion() {
      this.setState(prevState => ({
        isOpen: !prevState.isOpen
      }));
    }
  
    render() {
      const { isOpen } = this.state;
  
      return (
        <div className="accordion-item">
          <div className="accordion-title" onClick={this.toggleAccordion}>
            {this.props.title} <img src={isOpen ? "./images/drop-up.png" : "./images/drop-down.png"} alt={isOpen ? 'arrow up' : 'arrow down'} />
          </div>
          {isOpen && (
            <div className="accordion-content">
              {this.props.children}
            </div>
          )}
        </div>
      );
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            tokenAction: tokenAction,
        },
        dispatch,
    );

const mapStateToProps = state => {
    return {
        token: state.token,
        profile: state.profile
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(FAQ));
