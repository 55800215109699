import React from 'react';
import {bindActionCreators} from "redux";
import {tokenAction} from "../redux-stuffs/actions/token_action";
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";
import Header from "../components/Header";

class Help extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

render () {
  return (
    <div>
    <Header/>
    <div className="container mt-4">
        <div className="spare-dvs vh-100 align-items-start">
            <div className="w-100 float-left">
                <h1>Help & FAQ</h1>
                <div className="lctn-dvs dashboard-main-buttons">
                    <div>
                    <a href="https://tawk.to/chat/5e39c317a89cda5a18841f2d/1ejij9n9t" target="_blank" rel="noopener noreferrer">
                        Open Chat
                    </a>
                    </div>
                    <div>
                    <a href="tel:+1(424)744-0155" style={{ wordWrap: 'break-word:' }}>Give us a call at: <br/>+1 (424) 744-0155</a>
                    </div>
                    <div>
                    {/* <a href="https://www.gotspare.com/faq/" target="_blank" rel="noopener noreferrer">
                        FAQ
                    </a> */}
                    <Link to='/faq'>FAQ</Link>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
  );
};
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            tokenAction: tokenAction,
        },
        dispatch,
    );

const mapStateToProps = state => {
    return {
        token: state.token,
        profile: state.profile
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Help));
