import React from "react";
import {Form, Modal} from "antd";
import api from '../api/api/index';
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {tokenAction} from "../redux-stuffs/actions/token_action";


function error(title, content) {
    Modal.error({
        title: title,
        content: content,
    });
}

class EnterOtpComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    handleFormSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let finalBody = {
                    otp: values.otp,
                };

                api.enterOtp(finalBody)
                    .then(res => {
                        if (res.status !== 104) {
                            console.log(res);
                            error("Error", res.message);
                        } else {
                            console.log(res);
                            this.props.tokenAction(this.props.token)
                                .then(_ => {
                                    let thisVar = this;
                                    Modal.success({
                                        title: "Success",
                                        content: "Successfully Verified Phone Number",
                                        onOk: () => {
                                            thisVar.props.history.push({
                                                pathname: "/spare"
                                            })
                                        }
                                    });
                                })
                            ;
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        error("Error", "One Time Pin is incorrect. Please try again.");
                    })

            }
        });
    };

    render() {

        const {getFieldDecorator} = this.props.form;


        return (
            <div className="container mt-5">
            <div className="spare-dvs vh-100 align-items-start">
                <div className="w-100 float-left">
                        <h2>Enter OTP</h2>
                        <p>*One Time Password is sent to the phone number used during sign up.</p>
                        <div className="email-dvs">
                            <Form onSubmit={this.handleFormSubmit}>

                                <Form.Item>
                                    {getFieldDecorator('otp', {
                                        rules: [{
                                            required: true,
                                            message: 'Please input the OTP sent to your phone!'
                                        }],
                                    })(
                                        <p>
                                            <input type="text" placeholder="Enter OTP"/>
                                        </p>,
                                    )}
                                </Form.Item>

                                <div className="btn-enter w-100 float-left">
                                    <input type="submit" value="Submit" name=""
                                            onClick={this.handleFormSubmit}/>
                                </div>
                            </Form>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

const WrappedEnterOtpForm = Form.create({name: 'enter_otp_form'})(EnterOtpComponent);

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            tokenAction: tokenAction
        },
        dispatch,
    );
const mapStateToProps = state => {
    return {
        token: state.token
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(WrappedEnterOtpForm));
