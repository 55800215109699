import React from "react";
import {Link} from "react-router-dom";

class Network extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="container mt-4">
                <div className="spare-dvs vh-100 align-items-start">
                    <div className="w-100 float-left">
                        <div className="ntwrk-dvs">
                            <h1>Welcome to the Network!</h1>
                        </div>

                        <div className="terms-dv">
                            <h6>Terms and conditions</h6>
                            <p>By hitting “Confirm” button you acknowledge you have read, understood, and AGREE
                                to Terms and Conditions</p>

                            <Link to="/dashboard">Confirm</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Network;
