import React from 'react';
import {bindActionCreators} from "redux";
import {tokenAction} from "../redux-stuffs/actions/token_action";
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";
import Header from "../components/Header";

class Promotions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    handleSubmit = (event) => {
        event.preventDefault();
    };

render () {
  return (
    <div>
    <Header/>
    <div className="container mt-4">
        <div className="spare-dvs vh-100 align-items-start">
            <div className="w-100 float-left">
                <h1>Promotions</h1>
                <div className="lctn-dvs dashboard-main-buttons">
                    <div>
                    <Link to='/dashboard'>Refer a business</Link>
                    </div>
                    <div>
                    <Link to='/dashboard'>Refer a friend</Link>
                    </div>
                    <div>
                    <Link to='/dashboard'>Activity bonus</Link>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
  );
};
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            tokenAction: tokenAction,
        },
        dispatch,
    );

const mapStateToProps = state => {
    return {
        token: state.token,
        profile: state.profile
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Promotions));
