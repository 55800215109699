import React from "react";
import {Form, Select, Button, message, Modal, Spin} from "antd";
import {bindActionCreators} from "redux";
import {tokenAction} from "../redux-stuffs/actions/token_action";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import api from "../api/api";
import {defaultFormValues} from "../api/requiredFunctions";
import {saveCashRequestDto} from "../redux-stuffs/actions/profile_action";
import {geocodeByAddress, getLatLng} from "react-places-autocomplete";
import {merchantAddressState, amountState} from '../atoms';
import withRecoil from '../withRecoil';

var _ = require('lodash');

const MIN_ESCROW_AMOUNT = 5;
const {Option} = Select;


function error(title, content) {
    Modal.error({
        title: title,
        content: content,
    });
}


class AmountAvailable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            businesses: [],
            apiCalled: false,
            value: 1,
            isApiLoading: false,
            address: '',
            suggestions: [],
            zipcode: '',
            isEditing: true,
            locationLoading: false,
            locationFound: false,
            balance: null,
            map: null,
            markers: [],
            selectedLocation: null,
            i: null,
            long: null,
            lat: null,
            defvalue: 20,
            requested_amount: 20,
        }
    }

    /**
     * Checks amount of money in account
     */
    checkAmount = () => {
        const escrow_amount = _.get(this.state, 'escrow_amount', null);

        let input_value = document.getElementById('amount').value;

        if(input_value > escrow_amount){
            this.setState ({
                requested_amount: escrow_amount
            })
        }
        else {
        this.setState ({
            requested_amount: input_value
        })
        }
    };

    componentWillMount(){
        this.hasActiveCashRequest();
        this.updateEscrow();
    }

    /**
     * finds 9 locations near the zipcode given
     */
    fetchExistingLocations = () => {
        try {
            const { businesses } = this.state;
            const { map } = this.state;
            const markers = [];
            businesses.forEach(business => {
                const position = {lat: business.latitude, lng: business.longitude}
                const marker = new window.google.maps.Marker({
                    position: position,
                    map,
                    title: business.business_name,
                });

                marker.addListener('click', () => {
                    this.handleClick(business);
                });

                markers.push(marker);
            });
            this.setState({ markers });
            console.log("existing locations fetched")
        } catch (error) {
            console.log(error.message);
        }
    }

    /**
     * clickable directions button leads them to google page w directions
     */
    findDirections = async () => {
        const location = this.state.selectedLocation
        if (location){
            const { address, business_name } = location
            // directions to address from user location
            const dUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}`
            // search result of location name in google maps
            //const sUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(business_name)}`;
            window.open(dUrl, '_blank')
            //window.open(sUrl, '_blank')
        }
    }


    handleClick = (location) => {
        console.log("selected location: ", location);
        this.setState({ selectedLocation: location });
    }

    /**
     * handle functionality of selecting a location from the drop down
     * sends it to the google api to retrieve the zipcode if the location is viable
     */
    handleSelect = async(address, placeId) => {
        this.setState({address})
        geocodeByAddress(address)
        .then((results)=> getLatLng(results[0]))
        .then(({lat, lng}) => {
            console.log('Lat and Long: ', lat, lng)
            this.setState({lat: lat, long: lng})
            const apiKey = "AIzaSyACt6UKlvoZMTyzXRS9Y4Pcun9ku30r8pY";
            const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
            fetch(apiUrl)
            .then((response) => {
                if(!response.ok){
                    throw new Error(`Status: ${response.status}`)
                }
                return response.json();
            })
            .then((data) => {
                const firstRes = data.results[0]
                const zipcode = firstRes?.address_components.find(
                    (component) => component.types.includes("postal_code")
                )?.short_name;
                if(zipcode){
                    this.setState({zipcode: zipcode, isEditing: false})
                    this.props.form.setFieldsValue({
                        zip: zipcode,
                    })
                }
                else{
                    console.error("No valid zipcode")
                }
            })
            .catch((error) => {
                console.error("Error fetching location details: ", error.message);
            });
        })
        .catch((error) => {
            console.error("Error: ", error.message);
        });
    }

    /**
     * shows location options when user begins to type in a location
     */
    handleSearch = (value) => {
        const autocompleter = new window.google.maps.places.AutocompleteService();
        autocompleter.getPlacePredictions(
            {
                input: value,
                types: ['geocode'],
            },
            (predictions, status) => {
                if(status === window.google.maps.places.PlacesServiceStatus.OK){
                    const results = predictions.slice(0, 20);

                    this.setState({suggestions: results})
                }
            }
        )
    }

    handleZipcodeChange = (value) => {
        this.setState({ zipcode: value, isEditing: true });
    };

    /**
     * searches for locations near the zipcode with enough money
     * returning a list of maximum 9 locations
     */
    handleFormSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            console.log("handle form submit: ", values);
        const {zipcode} = this.state;
            if (!err) {


                let finalBody = {
                    ...defaultFormValues(),
                    zip: zipcode,
                    amount: this.state.requested_amount
                };

                api.searchZip(finalBody)
                    .then(res => {
                        if (res.status !== 105) {
                            error("Error", res.message);
                        } else {
                            this.setState({
                                apiCalled: true,
                                businesses: res.data
                            });
                            console.log("business data: ", res.data)
                            this.loadGoogleMapsApi();
                        }
                    })
                    .catch(err => {
                        error("Error","Internal Server Error. Please try again later");
                    })
            }
        });
    };

    /**
     * keep track of whether the cash request has expired or not
     */
    hasActiveCashRequest(){
        let body = {
            access_token: this.props.token,
            device_type: 5,
            device_token: 1,
            app_version: 1
        }
        api.accessTokenLogin(body)
            .then(res => {
                if (res.status !== 105) {
                    error("Error", res.message);
                } else {

                    let body2 = {
                        access_token: this.props.token,
                        request_type: 1
                    };

                    api.cashRequestHistory(body2)
                        .then(res => {
                            console.log("request response: ", res.status)
                            console.log("request data: ", res.data)
                            if (res.status == 105 && res.data) {
                                this.props.history.push({
                                    //pathname: '/dashboard/pickup'
                                })
                            }
                        })
                        .catch(err => {
                            this.props.history.push({
                                pathname: 'login'
                            })
                        })


                    const escrow_amount = _.get(res,'data.escrow_amount');
                    this.setState({
                        escrow_amount
                    });
                    this.props.profile.escrow_amount = res.data.escrow_amount;
                    let defvalue = escrow_amount < 20 ? escrow_amount : 20;
                    this.setState({
                        defvalue,
                    });
                }
            })
            .catch(err => {
                //error("Error", "Access token login broke");

                this.props.history.push({
                    pathname: 'login'
                })
            })
    }

    /**
     * initialization of the google maps api
     */
    loadGoogleMapsApi = async() => {
        const loadScript = () => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyACt6UKlvoZMTyzXRS9Y4Pcun9ku30r8pY&libraries=places`;
                script.async = true;
                script.defer = true;
                script.onload = resolve;
                script.onerror = reject;
                document.body.appendChild(script);
            })
        }

        try {
            await loadScript();
            console.log("Google Maps API loaded successfully");
            await this.initMap();
            await this.fetchExistingLocations();
        } catch (error) {
            console.error("Failed to load Google Maps API: ", error.message);
        }
    }

    /**
     * initializes the map as well as set center to users zipcode
     */
    initMap = async() => {
        try {
            const position = {lat: this.state.lat, lng: this.state.long}
            let map = new window.google.maps.Map(document.getElementById("map"), {
                center: position,
                zoom: 10,
            });
            let circle = new window.google.maps.Circle({
                strokeColor: '#ADD8E6',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#ADD8E6',
                fillOpacity: 0.35,
                map: map,
                center: new window.google.maps.LatLng(this.state.lat,
                    this.state.long),
                radius: 24140.2
            });
            this.setState({map})
            console.log(this.state.map)
        } catch (error) {
            console.error("error initializing map ", error.message)
        }
    }

    /**
     * if user clicks "get current location"
     * function finds zipcode of user's lat and long
     */
    locateZip = async() => {
        console.log("locateZip")
        console.log(this.props.profile)

        if("geolocation" in navigator){
            // location tracking here
            this.setState({locationLoading: true})
            navigator.geolocation.getCurrentPosition(
                (position)=> {
                    const { latitude, longitude } = position.coords;
                    console.log("latitude: " + latitude)
                    console.log("longitude: " + longitude)
                    this.setState({lat: latitude, long: longitude})
                    const apiKey = "AIzaSyACt6UKlvoZMTyzXRS9Y4Pcun9ku30r8pY";
                    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
                    fetch(apiUrl)
                        .then((response) => {
                            if(!response.ok){
                                throw new Error(`Status: ${response.status}`)
                            }
                            return response.json();
                        })
                        .then((data) => {
                            const firstRes = data.results[0]
                            const zipcode = firstRes?.address_components.find(
                                (component) => component.types.includes("postal_code")
                            )?.short_name;
                            if(zipcode){
                                this.setState({zipcode: zipcode, isEditing: false, address: zipcode})
                                console.log("Zipcode: " + this.state.zipcode)
                                this.props.form.setFieldsValue({
                                    address: zipcode,
                                    zip: zipcode,
                                })
                                this.setState({locationFound: true})
                                this.setState({locationLoading: false})
                                setTimeout(() => {
                                    this.setState({locationFound: false});
                                  }, 3000);
                            }
                            else{
                                console.error("No valid zipcode")
                                this.setState({locationLoading: false})
                            }
                        })
                        .catch((error) => {
                            console.error("Error fetching location details: ", error.message);
                            this.setState({locationLoading: false})
                        });
                },
                (error) => {
                    console.error("Error getting location: " + error.message);
                    this.setState({locationLoading: false})
                }
            );
        }
        else{
            // geolocation not supported
            this.setState({locationLoading: false})
            console.log("Geolocation is not supported in this browser")
        }
    }

    /**
     * sends the cash request to the business if the amount selected is within limit
     */
    selectAmount = () => {
        const {businesses, amount, selectedLocation} = this.state;
        const escrow_amount = _.get(this.state, 'escrow_amount', null);

        if (this.state.requested_amount > escrow_amount) {
            if(this.state.requested_amount > escrow_amount){
                console.log("input_value > escrow_amount: ",this.state.requested_amount, escrow_amount);
            }
            return;
        }
        this.setState({
            isApiLoading: true
        }, () => {

            let finalBody = {
                ...defaultFormValues(),
                amount: this.state.requested_amount,
                currency: 1,
                agent_registration_id: selectedLocation.registration_id
            };

            api.sendCashRequest(finalBody)
                .then(res => {
                    this.setState({
                        isApiLoading: false
                    }, () => {
                        if (res.status !== 104) {

                            Modal.error({
                                title: "Error",
                                content: res.message
                            })
                        } else {
                            // atom
                            this.props.recoilStates.merchantAddressState.setState(selectedLocation.address)
                            this.props.recoilStates.amountState.setState(this.state.requested_amount)
                            this.props.saveCashRequestDto(res.data)
                                .then(_ => {
                                    this.props.history.push({
                                        pathname: '/dashboard/pickup'
                                    })
                                })
                        }
                    });

                })
                .catch(err => {
                    message.error("Internal Server Error. Please try again later");
                    this.setState({
                        isApiLoading: false
                    });
                })
        });
    };

    updateEscrow(){

        let body = {
            access_token: this.props.token,
            device_type: 5,
            device_token: 1,
            app_version: 1
        }
        api.accessTokenLogin(body)
            .then(res => {
                if (res.status !== 105) {
                    error("Error", res.message);
                } else {
                    const escrow_amount = _.get(res,'data.escrow_amount');
                    this.setState({
                        escrow_amount
                    });
                    this.props.profile.escrow_amount = res.data.escrow_amount;
                    let defvalue = escrow_amount < 20 ? escrow_amount : 20;
                    this.setState({
                        defvalue,
                    });
                }
            })
            .catch(err => {
                //error("Error", "Access token login broke");

                this.props.history.push({
                    pathname: 'login'
                })
            })
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {businesses, apiCalled, defvalue, isApiLoading} = this.state;
        const { address, suggestions, locationLoading, locationFound, requested_amount } = this.state;

        const escrow_amount = _.get(this.state, 'escrow_amount', null);

        let profile = {
            escrow_amount: escrow_amount
        };

        if(!profile) {
            profile = {
                escrow_amount: -1
            }
        }

        let balance = 0;

        if (escrow_amount > 0 ) {
            let number = escrow_amount.toString()
            balance = number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }


        return (
                <div className="container mt-4">
                    <div className="spare-dvs vh-100 align-items-start">
                        <div className="w-100 float-left">

                            {apiCalled ?
                            <div>
                                    <div>
                                    <h2>Select a location to pickup your ${requested_amount} </h2>
                                    <h3> *Disclaimer: Search radius is limited to 15 miles* </h3>
                                    <br></br>
                                    <div id="map" style={{ height: "400px", width: "100%" }}></div>

                                    {this.state.selectedLocation && (
                                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        <div className="location-info">
                                            <p style={{fontWeight: "bold"}}>{this.state.selectedLocation.business_name}</p>
                                            <p>{this.state.selectedLocation.address}</p>
                                            <button className="directions-select" onClick={this.findDirections}>Directions</button>
                                            <button className="directions-select" onClick={this.selectAmount}>Withdraw</button>
                                        </div>
                                        </div>
                                    )}
                                    <br/>
                                    </div>
                                    <div>
                                        <button onClick={() => window.location.reload()} style={{color: "white", padding: "10px 20px", backgroundColor: "#4C6EF8", border: "none", borderRadius:"55px"}}>
                                        Cancel
                                        </button>
                                    </div>
                                </div>
                                :
                            <div>
                            <h2>Current Funds: ${balance}</h2>
                            <hr />
                            <h3>Enter Amount ($)<br/>

                                <div className="ant-input-number ant-input-number-lg">
                                    <div className="ant-input-number-handler-wrap"><span
                                        unselectable="unselectable" role="button" aria-label="Increase Value"
                                        aria-disabled="false"
                                        className="ant-input-number-handler ant-input-number-handler-up "><i
                                        aria-label="icon: up"
                                        className="anticon anticon-up ant-input-number-handler-up-inner"><svg
                                        viewBox="64 64 896 896" focusable="false" className="" data-icon="up"
                                        width="1em" height="1em" fill="currentColor" aria-hidden="true"><path
                                        d="M890.5 755.3L537.9 269.2c-12.8-17.6-39-17.6-51.7 0L133.5 755.3A8 8 0 0 0 140 768h75c5.1 0 9.9-2.5 12.9-6.6L512 369.8l284.1 391.6c3 4.1 7.8 6.6 12.9 6.6h75c6.5 0 10.3-7.4 6.5-12.7z"></path></svg></i></span><span
                                        unselectable="unselectable" role="button" aria-label="Decrease Value"
                                        aria-disabled="false"
                                        className="ant-input-number-handler ant-input-number-handler-down "><i
                                        aria-label="icon: down"
                                        className="anticon anticon-down ant-input-number-handler-down-inner"><svg
                                        viewBox="64 64 896 896" focusable="false" className="" data-icon="down"
                                        width="1em" height="1em" fill="currentColor" aria-hidden="true"><path
                                        d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg></i></span>
                                    </div>
                                    <div className="ant-input-number-input-wrap">
                                        <input defaultValue={defvalue} className="form-control ant-input-number-input" min="1" max="200"
                                                id="amount" name="amount"
                                                ref="amount" type="text" onChange={() => this.checkAmount()} />
                                    </div>
                                </div>
                            </h3>

                                <Form onSubmit={this.handleFormSubmit}>
                                    <Form.Item>
                                        {getFieldDecorator('address', {
                                            rules: [
                                            {
                                                required: true,
                                                message: 'Please input your address!',
                                            },
                                            ],
                                        })(
                                            <Select
                                            showSearch
                                            placeholder="Enter City, State, or Zipcode and Select an Option"
                                            optionFilterProp="children"
                                            onSelect={this.handleSelect}
                                            onSearch={this.handleSearch}
                                            filterOption={false}
                                            >
                                            {suggestions.map((suggestion) => (
                                                <Option key={suggestion.place_id} value={suggestion.description}>
                                                {suggestion.description}
                                                </Option>
                                            ))}
                                            </Select>
                                        )}
                                        </Form.Item>
                                    <div></div>
                                    <Button style={{backgroundColor: '#4C6EF8', borderRadius: '55px', color: "#fff"}} onClick={this.locateZip}>
                                        Get Current Location
                                    </Button>
                                    <span style={{ marginLeft: '8px', display: 'inline-block', verticalAlign: 'middle' }}>
                                    {locationLoading && <Spin />}
                                    {locationFound && <span style={{fontSize: "20px", color: "green"}}>     &#10003;</span>}
                                    </span>
                                    <Form.Item className="cshrdy-dv">
                                        <div className="w-100 float-left btn-cash">
                                            <input type="submit" value="Search" name=""
                                                    onClick={this.handleFormSubmit} style={{marginTop: 0}}/>
                                        </div>
                                    </Form.Item>
                                </Form>
                                </div>
                            }
                                </div>
                            </div>
                        </div>
        )
    }
}

const WrappedAmountAvailableForm = Form.create({ name: 'amount_available_form' })(AmountAvailable);

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      tokenAction,
      saveCashRequestDto,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    token: state.token,
    profile: state.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(withRecoil(WrappedAmountAvailableForm, [merchantAddressState, amountState]))
);
