import api from './api';
import queryString from 'querystring';

function requiresAccessToken(obj) {
    try {
        const persistRoot = JSON.parse(localStorage.getItem('persist:root'));
        obj = {...obj, access_token: JSON.parse(persistRoot.token)};
        return obj;
    } catch (err) {
        window.location.href = "/login"
    }
}

function getToken() {
    try {
        let persistRoot = JSON.parse(localStorage.getItem('persist:root'));
        return JSON.parse(persistRoot.token);
    } catch (err) {
        window.location.href = "/login"
    }
}

export default class Implementation extends api {
    constructor(url) {
        super();
        this.url = url;
    }

    getURL() {
        return this.url;
    }

    customFetch(input, init = null) {
        return fetch(input, init).then((response) => {
            if (response.ok) {
                return response.json();
            } else throw response;
        });
    }

    accessTokenLogin(body) {
        return this.customFetch(this.url + "/access_token_login",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: queryString.stringify(body)
            })
    }
    
    register(body) {
        return this.customFetch(this.url + "/phone_signup",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: queryString.stringify(body)
            })
    }

    login(body) {
        return this.customFetch(this.url + "/phone_login",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: queryString.stringify(body)
            })
    }

    enterOtp(body) {
        body = requiresAccessToken(body);
        return this.customFetch(this.url + "/check_otp",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: queryString.stringify(body)
            })
    }

    searchZip(body) {
        body = requiresAccessToken(body);
        return this.customFetch(this.url + "/show_agent",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: queryString.stringify(body)
            })
    }

    sendCashRequest(body) {
        body = requiresAccessToken(body);
        return this.customFetch(this.url + "/send_cash_request",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: queryString.stringify(body)
            })
    }

    cancelCashRequest(body) {
        body = requiresAccessToken(body);
        return this.customFetch(this.url + "/request_action_by_customer",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: queryString.stringify(body)
            })
    }

    checkCashReq(body) {
        body = requiresAccessToken(body);
        return this.customFetch(this.url + "/cash_request_status",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: queryString.stringify(body)
            })
    }

    updateEscrowAmount(body) {
        body = requiresAccessToken(body);
        return this.customFetch(this.url + "/update_user_escrow_amount",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: queryString.stringify(body)
            })
    }

    cashRequestHistory(body) {
        body = requiresAccessToken(body);
        return this.customFetch(this.url + "/cash_request_history",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: queryString.stringify(body)
            })
    }

    checkingCashingUpload(body) {
        return this.customFetch(this.url + "/check_cashing/upload",
            {
                method: 'POST',
                /*headers: {
                    'Content-Type': 'multipart/form-data;charset=UTF-8'
                },*/
                body: body
            })
    }
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    

    testGet() {
        return this.customFetch(this.url + "/api/v1/themes",
            {
                method: 'GET',
                headers: {
                    Authorization: getToken()
                }
            })
    }

    testPost(body) {
        return this.customFetch(this.url + "/api/v1/user/username", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: queryString.stringify(body)
        });
    }

}
