import React from "react";
import PhoneInput from 'react-phone-input-2';
import {Form, Input, Modal} from "antd";
import api from "../api/api/index";
import {defaultFormValues} from "../api/requiredFunctions";
import {bindActionCreators} from "redux";
import {tokenAction} from "../redux-stuffs/actions/token_action";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {profileAction} from "../redux-stuffs/actions/profile_action";


function error(title, content) {
    Modal.error({
        title: title,
        content: content,
    });
}


class LoginComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: ""
        }
    }

    handleFormSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.phone = values.phone.replace(/[- )(]/g, '');
                let finalBody = defaultFormValues();
                finalBody = {
                    ...finalBody,
                    ...values
                };


                api.login(finalBody)
                    .then(res => {
                        if (res.status !== 105) {
                            return error("Error", res.message);
                        }
                        // atom
                        this.props.tokenAction(res.data.access_token)
                            .then(_ => {
                                this.props.profileAction(res.data)
                                    .then(_ => {
                                        this.props.history.push({
                                            pathname: '/dashboard'
                                        })
                                    })
                            });
                    });
            } else {
                error({
                    title: "Error",
                    content: "Please enter a valid phone number and password"
                });
            }
        });
    };

    handleFormSubmitPIN = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                values.phone = values.phone.replace(/[- )(]/g, '');
                let finalBody = defaultFormValues();
                finalBody = {
                    ...finalBody,
                    ...values
                };


                api.login(finalBody)
                    .then(res => {
                        if (res.status !== 105) {
                            return error("Error", res.message);
                        }
                        this.props.tokenAction(res.data.access_token)
                            .then(_ => {
                                this.props.profileAction(res.data)
                                    .then(_ => {
                                        this.props.history.push({
                                            pathname: '/dashboard'
                                        })
                                    })
                            });
                    });
            } else {
                alert("here");
                error({
                    title: "Error",
                    content: "Please enter a valid phone number and password"
                });
            }
        });
    };

    slidediv = (e) => {

        e.preventDefault();
        if (this.state.myclass !== 'forgot_pw_div') {
            this.setState({
                myclass: 'forgot_pw_div'
            })
        }
        else {
            this.setState({
                myclass: '',
            })
        }
    }


    render() {

        const {getFieldDecorator} = this.props.form;

        return (
            <div className="container mt-4">
                <div className="spare-dvs vh-100 align-items-start">
                    <div className="w-100 float-left">
                    <div style={{ padding: '30px' }}>
                        <img
                            src={process.env.PUBLIC_URL + "/images/spare_logo_full.png"}
                            alt="Spare Logo"
                            className="responsive-image"
                            style={{maxWidth:'30%'}}
                        />
                    </div>
                        <h2>Welcome back</h2>
                        <div className="email-dvs">

                            <Form onSubmit={this.handleFormSubmit}>

                                <Form.Item>
                                    {getFieldDecorator('phone', {
                                        rules: [{
                                            required: true,
                                            message: 'Please enter your phone number!'
                                        },
                                            {
                                                validator: (rule, value, callback) => {
                                                    value = value.replace(/[- +)(]/g, '');
                                                    if (value.length < 8) {
                                                        callback("Please enter a valid number")
                                                    } else {
                                                        callback();
                                                    }
                                                }
                                            }],
                                    })(
                                        <PhoneInput
                                            country={'us'}
                                            style={{
                                                width: "100%",
                                                marginBottom: "20px"
                                            }}
                                        />,
                                    )}
                                </Form.Item>

                                {getFieldDecorator('password', {
                                    rules: [{
                                        required: true,
                                        message: 'Please input your password!'
                                    }],
                                })(
                                    <p>
                                        <Input.Password type="password" name="" placeholder="Password" class={this.state.pwclass} />
                                    </p>,
                                )}



                                <div className="btn-enter w-100 float-left mg-bottom-30">
                                    <input type="submit" value="Login" name=""/>
                                </div>
                            </Form>
                        </div>
                        <p> Don't have an account? </p>
                        <p className="signup-button" onClick={() => {
                            this.props.history.push({
                                pathname: '/signup'
                            })
                        }}>
                          Sign up
                        </p>
                        {/* <a href='/signup' >Sign up</a> */}
                    </div>
                </div>
            </div>
        )
    }
}

/*


                                            <button id="forgot-password" onClick={this.slidediv}>Forgot Password?</button>


                                            <div id="seconddiv" className={this.state.myclass}>
                                                <p>Please enter phone number above and your PIN below</p>
                                                <p>

                                                </p>
                                                <p>
                                                    <button onClick={this.handleFormSubmitPIN}>Submit</button>
                                                </p>
                                            </div>

 */

const WrappedEnterEmailForm = Form.create({name: 'enter_otp_form'})(LoginComponent);

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            tokenAction: tokenAction,
            profileAction: profileAction
        },
        dispatch,
    );
const mapStateToProps = state => {
    return {
        token: state.token
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(WrappedEnterEmailForm));
