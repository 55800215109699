import React from "react";

class SignupVerify extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="container mt-4">
                <div className="spare-dvs1 vh-100 align-items-start">
                    <div className="w-100 float-left">
                        <h1>Sign up</h1>

                        <div className="verify-dvs">
                            <div className="verfy-txt">
                                <p>Press the button below to request an SMS verification code. Once you
                                    receive our text message at your phone number ending
                                    with <strong>XXXX</strong>, enter the code below.</p>

                                <a href="#">Not you? Sign out</a>
                            </div>

                            <div className="verify-inputs">
                                <p>Verification Code</p>
                                <input type="number" inputMode='numeric' pattern="[0-9]*" name="" id="" value="verification code"
                                        className="verfct-inpt"/>

                                <input type="submit" value="Send SMS Code" name=""
                                        className="send-btn"/>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SignupVerify;
