import React from "react";
import {Link} from "react-router-dom";
import {Form, Modal} from "antd";
import api from '../api/api/index';
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {tokenAction} from "../redux-stuffs/actions/token_action";
import PhoneInput from 'react-phone-input-2';
import {profileAction} from "../redux-stuffs/actions/profile_action";

function error(title, content) {
    Modal.error({
        title: title,
        content: content,
    });
}


class SignupComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    handleFormSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {

                let finalBody = {
                    password: values.password,
                    device_type: -1,
                    device_token: 0,
                    app_version: 0,
                    name: values.name,
                    date_of_birth: Date.now(),
                    source: "Web Portal",
                    email: values.email,
                    phone: values.phone.replace(/[- )(]/g, ''),
                };


                api.register(finalBody)
                    .then(res => {
                        if (res.status !== 105) {
                            error("Error", res.message);
                        } else {
                            this.props.tokenAction(res.data.access_token)
                                .then(_ => {
                                    this.props.profileAction(res.data)
                                        .then(_ => {
                                            let thisVar = this;
                                            thisVar.props.history.push({
                                                pathname: 'enter-otp'
                                            })
                                        })
                                })
                            ;
                        }
                    })
                    .catch(err => {
                        error("Error", err.message);
                    })

            }
            else{
                error("Error", err);
            }
        });
    };

    render() {

        const {getFieldDecorator} = this.props.form;


        return (
            <div className="container mt-4">
                <div className="spare-dvs vh-100 align-items-start">
                    <div className="w-100 float-left">
                    <div style={{ padding: '30px' }}>
                        <img
                            src={process.env.PUBLIC_URL + "/images/spare_logo_full.png"}
                            alt="Spare Logo"
                            className="responsive-image"
                            style={{maxWidth:'30%'}}
                        />
                    </div>
                        <h1>Sign up</h1>

                        <div className="email-dvs">
                            <Form onSubmit={this.handleFormSubmit}>

                                <Form.Item>
                                    {getFieldDecorator('phone', {
                                        rules: [{
                                            required: true,
                                            message: 'Please enter your phone number'
                                        },
                                            {
                                                validator: (rule, value, callback) => {
                                                    value = value.replace(/[- +)(]/g, '');
                                                    if (value.length < 8) {
                                                        callback("Please enter a valid number")
                                                    } else {
                                                        callback();
                                                    }
                                                }
                                            }],
                                    })(
                                        <PhoneInput
                                            country={'us'}
                                            style={{
                                                width: "100%",
                                                marginBottom: "10px",
                                                marginTop: "20px"
                                            }}
                                        />,
                                    )}
                                </Form.Item>

                                <Form.Item>
                                    {getFieldDecorator('name', {
                                        rules: [{required: true, message: 'Please input your full name!'}],
                                    })(
                                        <p>
                                            <input type="text" placeholder="Name"/>
                                        </p>,
                                    )}
                                </Form.Item>

                                <Form.Item>
                                    {getFieldDecorator('email', {
                                        rules: [{required: true, message: 'Please input your email!'}],
                                    })(
                                        <p>
                                            <input type="email" placeholder="Email"/>
                                        </p>,
                                    )}
                                </Form.Item>

                                <Form.Item>
                                    {getFieldDecorator('password', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please input your password!'
                                            },
                                            {
                                                validator: (rule, value, callback) => {
                                                    if (value.length >= 8) {
                                                        callback();
                                                    } else {
                                                        callback('Password must be at least 8 characters long!');
                                                    }
                                                }
                                            }
                                        ],
                                    })(
                                        <p>
                                            <input type="password" placeholder="Password"/>
                                        </p>,
                                    )}
                                </Form.Item>


                                <Form.Item>
                                    {getFieldDecorator('confirmPassword', {
                                        rules: [{
                                            required: true,
                                            message: 'Please re-enter your password!'
                                        },
                                            {
                                                validator: (rule, value, callback) => {
                                                    if (value === this.props.form.getFieldValue('password')) {
                                                        callback();
                                                    } else {
                                                        callback("Passwords are inconsistent.");
                                                    }
                                                }
                                            }
                                        ],
                                    })(
                                        <p>
                                            <input type="password" placeholder="Confirm Password"/>
                                        </p>
                                    )}
                                </Form.Item>

                                <Form.Item>
                                    {getFieldDecorator('termsAndConditions', {
                                        rules: [
                                            {
                                                required: true,
                                                message: ' '
                                            },
                                            {
                                                validator: (rule, value, callback) => {
                                                    if (value) {
                                                        callback();
                                                    } else {
                                                        callback('Please review the term and conditions!');
                                                    }
                                                }
                                            }
                                        ],
                                    })(
                                        <div>
                                        <div style={{display: 'flex', justifyContent: 'in-between', paddingTop:"10px"}}>
                                            <div style={{flex: '1'}}>
                                                <input type="checkbox"/>
                                            </div>
                                            <div style={{flex: '100'}}>
                                            <span>Please Accept the <a target="_blank" href="https://docs.google.com/document/d/1Kg7xb8oWzwVyqpV9rxF17rLdrJzJFMHaIZBfcRX7kRc/edit" rel="noopener noreferrer" className="sign-anchr">Terms and Conditions</a></span>
                                            </div>
                                        </div>
                                        </div>
                                    )}
                                </Form.Item>


                                <div className="btn-enter w-100 float-left">
                                    <input type="submit" value="Sign Up" name=""
                                            onClick={this.handleFormSubmit}/>
                                </div>

                                <div className="alrdy-acnt">
                                    <span>Already have an account? <a href="/login" className="sign-anchr">Log In</a></span>
                                </div>
                            </Form>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

const WrappedHorizontalSignUpForm = Form.create({name: 'signup_form'})(SignupComponent);

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            tokenAction: tokenAction,
            profileAction: profileAction
        },
        dispatch,
    );
const mapStateToProps = state => {
    return {
        token: state.token
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(WrappedHorizontalSignUpForm));
