import React from 'react';
import {bindActionCreators} from "redux";
import {tokenAction} from "../redux-stuffs/actions/token_action";
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";
import Header from "../components/Header";

class Security extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isToggled: false
        };
    }

    toggleBiometrics = () => {
        this.setState(prevState => ({
            isToggled: !prevState.isToggled
        }))
    }

    handleSubmit = (event) => {
        event.preventDefault();
    };

    render () {
        const {isToggled} = this.state
        return (
            <div>
            <Header/>
            <div className="container mt-4">
                <div className="spare-dvs vh-100 align-items-start">
                    <div className="w-100 float-left">
                        <h1>Security</h1>
                        <div className="lctn-dvs dashboard-main-buttons">
                            <div>
                            <Link to='/dashboard'>Security tier</Link>
                            </div>
                            <div>
                                <div className="toggle-container">
                                    <div className="label">{"Biometrics"}</div>
                                    <div className={`toggle-button ${isToggled ? 'active' : ''}`} onClick={this.toggleBiometrics} />
                                </div>
                            </div>
                            <div>
                            <Link to='/dashboard'>Request</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
    };
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            tokenAction: tokenAction,
        },
        dispatch,
    );

const mapStateToProps = state => {
    return {
        token: state.token,
        profile: state.profile
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Security));
