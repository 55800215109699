export default class Api {
    testApi() {
    }     //just a test api
    register() {
    }

    enterOtp() {
    }

    searchZip() {
    }

    sendCashRequest() {
    }

    cancelCashRequest() {
    }

    checkCashReq() {
    }

    updateEscrowAmount() {
    }

    showAgent() {
    }

    login() {
    }
    
    getURL(){
    }
}
