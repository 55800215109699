import React from "react";
import {Link} from "react-router-dom";

class Spare extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="container mt-4">
                <div className="spare-dvs vh-100 align-items-start">
                    <div className="w-100 float-left">
                        <h1>Welcome to SPARE! </h1>
                        <h3>Here's how it works:</h3>
                        <h3>
                        <ol style={{listStylePosition: "inside", textAlign: "left"}}>
                            <li>Add cash to your account from credit, debit, or check.
                            </li>
                            <br></br>
                            <li>Enter in how much cash you want to withdraw and browse nearby locations to submit a request for your cash.
                            </li>
                            <br></br>
                            <li>Show the merchant your one-time code and get your cash.
                            </li>
                        </ol>
                        </h3>
                        <br></br>
                        <h3>(And don't forget to bookmark SPARE for easy access to cash whenever, wherever!)</h3>
                        <a href="/dashboard">Get Started!</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Spare;
