import React from 'react';
import {bindActionCreators} from "redux";
import {tokenAction} from "../redux-stuffs/actions/token_action";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Header from "../components/Header";

class Communication extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isSMSToggle:false,
            isEmailToggle: false
        };
    }

    toggleSMS = () => {
        this.setState(prevState => ({
            isSMSToggle: !prevState.isSMSToggle
        }))
    }

    toggleEmail = () => {
        this.setState(prevState => ({
            isEmailToggle: !prevState.isEmailToggle
        }))
    }

    handleSubmit = (event) => {
        event.preventDefault();
    };

    render() {
        const {isSMSToggle, isEmailToggle} = this.state
        return (
            <div>
            <Header/>
            <div className="container mt-4">
                <div className="spare-dvs vh-100 align-items-start">
                    <div className="w-100 float-left">
                        <h1>Communication</h1>
                        <div className="lctn-dvs dashboard-main-buttons">
                            <div>
                                <div className="toggle-container">
                                    <div className="label">{"SMS opt-in"}</div>
                                    <div className={`toggle-button ${isSMSToggle ? 'active' : ''}`} onClick={this.toggleSMS}/>
                                </div>
                            </div>
                            <div>
                                <div className="toggle-container">
                                    <div className="label">{"Email opt-in"}</div>
                                    <div className={`toggle-button ${isEmailToggle ? 'active' : ''}`} onClick={this.toggleEmail}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        );
        };
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            tokenAction: tokenAction,
        },
        dispatch,
    );

const mapStateToProps = state => {
    return {
        token: state.token,
        profile: state.profile
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Communication));
