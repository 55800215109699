import React from 'react';
import {bindActionCreators} from "redux";
import {tokenAction} from "../redux-stuffs/actions/token_action";
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";
import {Modal, Form, Input, Button, Select, DatePicker, Card, Row, Col, Radio, Typography} from "antd";
import Header from "../components/Header";
import axios from 'axios';
import { accessTokenState } from '../atoms';
import withRecoil from '../withRecoil';
import api from "../api/api"

const { Title, Text } = Typography;

const us_state_abbrevs_names = {
    "AL": "Alabama", "AK": "Alaska", "AZ": "Arizona", "AR": "Arkansas", "CA": "California",
    "CO": "Colorado", "CT": "Connecticut", "DE": "Delaware", "FL": "Florida", "GA": "Georgia",
    "HI": "Hawaii", "ID": "Idaho", "IL": "Illinois", "IN": "Indiana", "IA": "Iowa", "KS": "Kansas",
    "KY": "Kentucky", "LA": "Louisiana", "ME": "Maine", "MD": "Maryland", "MA": "Massachusetts",
    "MI": "Michigan", "MN": "Minnesota", "MS": "Mississippi", "MO": "Missouri", "MT": "Montana",
    "NE": "Nebraska", "NV": "Nevada", "NH": "New Hampshire", "NJ": "New Jersey", "NM": "New Mexico",
    "NY": "New York", "NC": "North Carolina", "ND": "North Dakota", "OH": "Ohio", "OK": "Oklahoma",
    "OR": "Oregon", "PA": "Pennsylvania", "RI": "Rhode Island", "SC": "South Carolina", "SD": "South Dakota",
    "TN": "Tennessee", "TX": "Texas", "UT": "Utah", "VT": "Vermont", "VA": "Virginia", "WA": "Washington",
    "WV": "West Virginia", "WI": "Wisconsin", "WY": "Wyoming"
};

const months_arr = {
    1: "January", 2: "February", 3: "March", 4: "April", 5: "May", 6: "June",
    7: "July", 8: "August", 9: "September", 10: "October", 11: "November", 12: "December"
};

class CardDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipcode: '',
            ssn: '',
            cvv: '',
            editingCard: null,
            showForm: false,
            cards: [
                //{ id: 1, card_number: '4722', card_holder_name: 'Anjali Girivasan', expiry_date: '11/25' },
                // { id: 2, card_number: '1234', card_holder_name: 'Jessica Faylor', expiry_date: '12/25' }
            ],
            cardNumber: '',
            currentView: 'cardList', // 'cardList' or 'enterAmount'
            cardSelected: null,
            amount: 0,
            fee: 0,
            total: 0,
            errors: {},
        };
        this.calculateTotal = this.calculateTotal.bind(this);
    }

    handleCardInfoSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                axios.post('/astra_payout_action.php', values)
                    .then(response => {
                        // Handle success
                    })
                    .catch(error => {
                        // Handle error
                    });
            } else {
                console.error('Form is invalid');
            }
        });
    };

    toggleForm = () => {
        this.setState((prevState) => ({ showForm: !prevState.showForm }));
    };

    handleUseCardClick = (number) => {
        this.setState({ currentView: 'enterAmount', cardSelected: number });
    };

    calculateTotal = (e) => {
        if(e.target.value){
            this.setState({amount: parseFloat(e.target.value)})
            if(this.state.amount < 0){
                this.setState({amount: Math.abs(e.target.value)});
            }
        }
        else{
            this.setState({amount: 0})
        }

        const fee_schedule = `${api}/get_fee_schedule?access_token=${encodeURIComponent(this.props.recoilStates.accessTokenState.state)}&source=${encodeURIComponent("astra_debit")}`;
        fetch(fee_schedule)
        .then(response => response.json())
        .then(response => {
            const feePercentage = response.data[0].percentage / 100;
            const fee = this.state.amount * feePercentage;
            const total = this.state.amount + fee;

            this.setState({
                fee: isNaN(fee) ? 0 : fee,
                total: isNaN(total) ? 0 : total
            });
        })
        .catch(error => {
            console.error('Error fetching fee schedule:', error);
        });
    };

    handleBackToCardList = () => {
        this.setState({ currentView: 'cardList', cardSelected: null});
        const customEvent = { target: { value: 0 } };
        this.setState({ amount: 0 }, () => {
        this.calculateTotal(customEvent);
        });
    };

    handleFundSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // Fund account logic
            } else {
                console.error("Please choose a different card.");
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { firstName, lastName, phone, email, address1, address2, city, state, zipcode, ssn, cvv, cards, cardNumber, showForm, editingCard, currentView, cardSelected } = this.state;
        const currentYear = new Date().getFullYear();

        if (currentView === 'enterAmount' && cardSelected) {
            return (
                <div>
                <Header />
                <div className="full-desk w-100 float-left d-inline-block">
                    <div className="container mg-btm-100 mg-top-50">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="spare-dvs align-items-start">
                                    <div className="w-100 float-left">
                                    <h1>Enter Amount to Fund</h1>
                                    <h3>from Card **** **** **** {cardSelected.slice(-4)}</h3>

                                        <div className="row">
                                            <div className="col-lg-6 offset-lg-3">
                                                <div className="card-dtls">
                                                    <Form onSubmit={this.handleFundSubmit}>
                                                        <div className="row radio-amount-input mg-btm-50">
                                                            <div style={{backgroundColor: '#DBE2FE', margin: 'auto', padding: '20px', borderRadius: '20px'}}>
                                                                <div className="amount-dtls">
                                                                    <div>
                                                                        <p style={{fontSize: '18px'}}>Payment details</p>
                                                                        <Form.Item>
                                                                            {getFieldDecorator('value', {
                                                                                rules: [{
                                                                                    required: true,
                                                                                    message: 'Please input the amount!'
                                                                                }],
                                                                            })(
                                                                                <p>
                                                                                    <div className="payment-box">
                                                                                        <label >Deposit amount</label>
                                                                                        <Input type="number" id="amount" placeholder="0.00" onChange={this.calculateTotal} style={{padding: '10px'}} step="0.01" min={0} />
                                                                                    </div>
                                                                                </p>
                                                                            )}
                                                                        </Form.Item>
                                                                    </div>
                                                                    <div className="readonly-input">
                                                                        <Form.Item>
                                                                            {getFieldDecorator('fee', {})(
                                                                                <p>
                                                                                    <div className="payment-box">
                                                                                        <label>Transaction Fee</label>
                                                                                        <Input type="number" className="readonly-input" value={this.state.fee} style={{padding: '10px'}} id="fee" readOnly />
                                                                                    </div>
                                                                                </p>
                                                                            )}
                                                                        </Form.Item>
                                                                    </div>
                                                                    <div className="">
                                                                        <Form.Item>
                                                                            {getFieldDecorator('total', {})(
                                                                                <p>
                                                                                    <div className="payment-box">
                                                                                        <label>Total</label>
                                                                                        <Input type="number" className="readonly-input" value={this.state.total} style={{padding: '10px'}} id="total" readOnly />
                                                                                    </div>
                                                                                </p>
                                                                            )}
                                                                        </Form.Item>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <Form.Item className="col-12">
                                                                {getFieldDecorator('termsAndConditions', {
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            message: ' '
                                                                        },
                                                                        {
                                                                            validator: (rule, value, callback) => {
                                                                                if (value) {
                                                                                    callback();
                                                                                } else {
                                                                                    callback('Please review the terms and policies.');
                                                                                }
                                                                            }
                                                                        }
                                                                    ],
                                                                })(
                                                                    <div>
                                                                        <div style={{display: 'flex', justifyContent: 'in-between'}}>
                                                                            <div style={{flex: '1'}}>
                                                                                <input type="checkbox"/>
                                                                            </div>
                                                                            <div style={{flex: '100'}}>
                                                                                <span> I authorize the transaction on this date to
                                                                                SPARE in the amount shown above. I acknowledge
                                                                                having received all the goods/services related
                                                                                to or associated with this transaction, and
                                                                                furthermore agree not to dispute any amount or
                                                                                other aspect of this transaction at any time.
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <br/>
                                                                    </div>
                                                                )}
                                                            </Form.Item>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <input type="submit" name=""  value="Fund" style={{marginTop: '0px'}}
                                                                       className="fund-bt"/>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                        <a href='#' onClick={this.handleBackToCardList}>Back to Cards</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            );
        }

        return (
            <div>
                <Header />
                <div className="container mt-4" style={{ width: "100%" }}>
                    <div className="spare-dvs">
                        <div className="w-100 float-left">
                            <h1>Your Debit Cards</h1>
                            <br />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                        {cards.map((card, index) => (
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '16px' }} key={card.id}>
                                <Card
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '16px',
                                        backgroundColor: '#f5faff',
                                        border: '1px solid #e6e9f2',
                                        borderRadius: '8px',
                                        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                                        maxWidth: '90%'
                                    }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                            <Title level={4} style={{ fontWeight: 'bold', margin: '0 0 4px' }}>
                                                {`**** **** **** ${card.card_number.slice(-4)}`}
                                            </Title>
                                            <Text style={{ color: '#666', margin: '0 0 8px' }}>
                                                {`${card.card_holder_name} | exp. ${card.expiry_date}`}
                                            </Text>
                                            <div style={{ display: 'flex', gap: '10px' }}>
                                                <a
                                                    href="#"
                                                    style={{
                                                        backgroundColor: '#4C6EF8',
                                                        borderRadius: '55px',
                                                        color: '#fff',
                                                        display: 'inline-block',
                                                        textAlign: 'center',
                                                        padding: '10px 15px',
                                                        textDecoration: 'none'
                                                    }}
                                                    onClick={() => this.handleUseCardClick(card.card_number)}
                                                >
                                                    Use Card
                                                </a>
                                                <a
                                                    href="#"
                                                    style={{
                                                        backgroundColor: '#4C6EF8',
                                                        borderRadius: '55px',
                                                        color: '#fff',
                                                        display: 'inline-block',
                                                        textAlign: 'center',
                                                        padding: '10px 15px',
                                                        textDecoration: 'none'
                                                    }}
                                                >
                                                    Edit
                                                </a>
                                                <a
                                                    href="#"
                                                    style={{
                                                        backgroundColor: '#4C6EF8',
                                                        borderRadius: '55px',
                                                        color: '#fff',
                                                        display: 'inline-block',
                                                        textAlign: 'center',
                                                        padding: '10px 15px',
                                                        textDecoration: 'none'
                                                    }}
                                                >
                                                    Delete
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        ))}
                    </div>
                    <br />
                    <div className='spare-dvs'>
                        <div className="lctn-dvs dashboard-main-buttons">
                            <a
                                href="#"
                                onClick={this.toggleForm}
                            >
                                {showForm ? 'Hide Form' : 'Add New Card'}
                            </a>
                        </div>
                    </div>

                    {showForm && (
                        <div>
                            <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
                                <Form onSubmit={this.handleCardInfoSubmit}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Form.Item label="Card Holder First Name" style={{ marginRight: '5px', flex: '1' }}>
                                            {getFieldDecorator('firstName', {
                                                rules: [{ required: true, message: 'Please input your first name!' }],
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>

                                        <Form.Item label="Card Holder Last Name" style={{ flex: '1' }}>
                                            {getFieldDecorator('lastName', {
                                                rules: [{ required: true, message: 'Please input your last name!' }],
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Form.Item label="Phone" style={{ marginRight: '5px', flex: '1' }}>
                                            {getFieldDecorator('phone', {
                                                rules: [
                                                    { required: true, message: 'Please input your phone number!' },
                                                    { len: 10, message: 'Phone number must be 10 digits long' }
                                                ],
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>

                                        <Form.Item label="Email" style={{ flex: '1' }}>
                                            {getFieldDecorator('email', {
                                                rules: [{ required: true, message: 'Please input your email!' }],
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Form.Item label="Address1" style={{ flex: '1' }}>
                                            {getFieldDecorator('address1', {
                                                rules: [{ required: true, message: 'Please input your address!' }],
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Form.Item label="Address2" style={{ flex: '1' }}>
                                            {getFieldDecorator('address2', {
                                                rules: [{ required: true, message: 'Please input your address!' }],
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Form.Item label="City" style={{ marginRight: '5px', flex: '1' }}>
                                            {getFieldDecorator('city', {
                                                rules: [{ required: true, message: 'Please input your city!' }],
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>

                                        <Form.Item label="State" style={{ marginRight: '5px', flex: '1' }}>
                                            {getFieldDecorator('state', {
                                                rules: [{ required: true, message: 'Please select your state!' }],
                                            })(
                                                <Select>
                                                    {Object.entries(us_state_abbrevs_names).map(([stateAbbrev, state]) => (
                                                        <Select.Option key={stateAbbrev} value={stateAbbrev}>{state}</Select.Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>

                                        <Form.Item label="Zip Code" style={{ flex: '1' }}>
                                            {getFieldDecorator('zipcode', {
                                                rules: [
                                                    { required: true, message: 'Please input your zip code!' },
                                                    { len: 5, message: 'Zip code must be 5 digits long' }
                                                ],
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Form.Item label="Date of Birth" style={{ flex: '1' }}>
                                            {getFieldDecorator('dob', {
                                                rules: [{ required: true, message: 'Please input your date of birth!' }],
                                            })(
                                                <DatePicker picker="date" />
                                            )}
                                        </Form.Item>

                                        <Form.Item label="Last 4 of SSN" style={{ flex: '1' }}>
                                            {getFieldDecorator('ssn', {
                                                rules: [
                                                    { required: true, message: 'Please input your SSN!' },
                                                    { len: 4, message: 'SSN must be 4 digits' }
                                                ],
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Form.Item label="Debit Card Number" style={{ marginRight: '5px', flex: '1' }}>
                                            {getFieldDecorator('cardNumber', {
                                                rules: [
                                                    { required: true, message: 'Please input your card number!' },
                                                    { len: 16, message: 'Card number must be 16 digits long' }
                                                ],
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>

                                        <Form.Item label="CVV" style={{ flex: '1' }}>
                                            {getFieldDecorator('cvv', {
                                                rules: [
                                                    { required: true, message: 'Please input your CVV!' },
                                                    { len: 3, message: 'CVV must be 3 digits long' }
                                                ],
                                            })(
                                                <Input />
                                            )}
                                        </Form.Item>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Form.Item label="Expiration Month" style={{ marginRight: '5px', flex: '1' }}>
                                            {getFieldDecorator('exp_month', {
                                                rules: [{ required: true, message: 'Please select expiration month!' }],
                                            })(
                                                <Select>
                                                    {Object.entries(months_arr).map(([value, month]) => (
                                                        <Select.Option key={value} value={value}>{month}</Select.Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>

                                        <Form.Item label="Expiration Year" style={{ flex: '1' }}>
                                            {getFieldDecorator('exp_year', {
                                                rules: [{ required: true, message: 'Please select expiration year!' }],
                                            })(
                                                <Select>
                                                    {Array.from({ length: 51 }, (_, i) => currentYear + i).map(year => (
                                                        <Select.Option key={year} value={year}>{year}</Select.Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </div>

                                    <Form.Item>
                                        <div className='spare-dvs'>
                                            <div className="lctn-dvs dashboard-main-buttons">
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <a href="#">
                                                        {editingCard ? 'Update Card' : 'Add Card'}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const WrappedCardDetails = Form.create({ name: 'card_details' })(CardDetails);

export default withRecoil(WrappedCardDetails, [accessTokenState]);
