import React from 'react';
import {bindActionCreators} from "redux";
import {tokenAction} from "../redux-stuffs/actions/token_action";
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";
import Header from "../components/Header";
import api from "../api/api";

class Profile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //  isToggled: false
            profilePic: ""
         };
    }

    componentDidMount = () => {
        this.fetchProfilePic();
    }

    fetchProfilePic = () => {
        let body = {
            access_token: this.props.token,
            device_type: 3,
            device_token: 1,
            app_version: 1
        }
        api.accessTokenLogin(body)
        .then(response => {
            console.log("profile pic: ", response.data.profile_pic)

            const isValidUrl = (string) => {
                try {
                  new URL(string);
                  return true;
                } catch (_) {
                  return false;  
                }
              };
              
              if (typeof response.data.profile_pic === 'string' && isValidUrl(response.data.profile_pic)) {
                this.setState({profilePic: response.data.profile_pic});
              } else {
                console.error('Invalid profile picture URL:', response.data.profile_pic);
                this.setState({profilePic: "https://spare-app.s3.us-west-1.amazonaws.com/images/default_profile_pic.png"});
              }
        })
    }

    // toggleBooster = () => {
    //     this.setState(prevState => ({
    //         isToggled: !prevState.isToggled
    //     }))
    // }

    render() {
        // const {isToggled} = this.state
        return (
            <div>
                <Header/>
                <div className="container mt-4">
                    <div className="spare-dvs vh-100 align-items-start">
                    <div className="w-100 float-left">
                    <h1>Profile</h1>
                        <div className="lctn-dvs dashboard-main-buttons">
                        <div className="profile-page">
                            <img src={this.state.profilePic} alt="Profile Pic" className="profile-image" />
                            <div className="profile-info">
                                <label style={{fontSize: "18px", fontWeight:"bold"}}>{this.props.profile.name}</label>
                                <br></br>
                                <label>{this.props.profile.email}</label>
                                <br></br>
                                <label>{this.props.profile.phone}</label>
                            </div>
                        </div>
                        <div>
                            <Link to="/edit-profile">Edit Profile</Link>
                        </div>
                        <div>
                            <Link to='/history'> Transaction History</Link>
                        </div>
                        {/* <div>
                            <div className="toggle-container">
                                <div className="label">{"Saving Booster"}</div>
                                <div className={`toggle-button ${isToggled ? 'active' : ''}`} onClick={this.toggleBooster}/>
                            </div>
                        </div> */}
                        {/* <div>
                            <Link to='/dashboard'>Lending</Link>
                        </div> */}
                        {/* <div>
                            <Link to='/dashboard'>Delete account</Link>
                        </div> */}
                    </div>
                    </div>
                </div>
                </div>
            </div>
        );
    };
    }

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            tokenAction: tokenAction,
        },
        dispatch,
    );

const mapStateToProps = state => {
    return {
        token: state.token,
        profile: state.profile
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Profile));
