import { atom } from 'recoil';
import sessionStorageEffect from './sessionStorage';

export const merchantAddressState = atom({
  key: 'merchantAddressState',
  default: '',
  effects: [sessionStorageEffect('merchantAddressState'),],
});

export const nameState = atom({
  key: 'nameState',
  default: '',
  effects: [sessionStorageEffect('nameState'),],
});

export const phoneState = atom({
  key: 'phoneState',
  default: '',
  effects: [sessionStorageEffect('phoneState'),],
});

export const amountState = atom({
  key: 'amountState',
  default: '',
  effects: [sessionStorageEffect('amountState'),],
});

export const accessTokenState = atom({
  key: 'accessTokenState',
  default: '',
  effects: [sessionStorageEffect('accessTokenState'),],
});
