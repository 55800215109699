import React from "react";
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import api from "../api/api";
import { Modal } from "antd";
import { accessTokenState, nameState, phoneState } from '../atoms';
import withRecoil from '../withRecoil';
import _ from 'lodash';

function error(title, content) {
  Modal.error({
    title: title,
    content: content,
  });
}

class DashboardMain extends React.Component {
  componentDidMount() {
    this.updateEscrow();
    this.getAccountInfo();
    this.props.recoilStates[accessTokenState.key].setState(this.props.token);
    console.log('Token:', this.props.token);
    console.log('Access Token State:', this.props.recoilStates[accessTokenState.key].state);
  }

  getAccountInfo = () => {
    fetch(`${api}/get_user_profile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        access_token: this.props.token,
      }),
    })
      .then(res => {
        if (!res.ok) {
          return res.json().then(errorData => {
            throw new Error(`Error: ${errorData.message}`);
          });
        }
        return res.json();
      })
      .then(data => {
        if (data.status !== 105) {
          throw new Error(`Error: ${data.message}`);
        } else {
          if (typeof data.data.name !== 'undefined' && typeof data.data.phone !== 'undefined') {
            this.props.recoilStates[nameState.key].setState(data.data.name);
            this.props.recoilStates[phoneState.key].setState(data.data.phone);
            console.log('Name:', data.data.name);
            console.log('Phone:', data.data.phone);
          } else {
            console.error(error);
          }
        }
      })
      .catch(error => {
        console.error('Error getting account info:', error);
      });
  };

  updateEscrow = () => {
    let body = {
      access_token: this.props.token,
      device_type: 5,
      device_token: 1,
      app_version: 1,
    };
    api.accessTokenLogin(body)
      .then(res => {
        if (res.status !== 105) {
          error("Error", res.message);
        } else {
          const escrow_amount = _.get(res, 'data.escrow_amount');
          this.setState({ escrow_amount });
          this.props.profile.escrow_amount = res.data.escrow_amount;
        }
      })
      .catch(err => {
        this.props.history.push({ pathname: 'login' });
      });
  };

  render() {
    const escrow_amount = _.get(this.state, 'escrow_amount', null);
    let profile = { escrow_amount: escrow_amount };

    if (!profile) {
      profile = { escrow_amount: -1 };
    }

    let balance = 0;

    if (escrow_amount > 0) {
      let number = escrow_amount.toString();
      balance = number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
      <div className="container mt-4">
        <div className="spare-dvs vh-100 align-items-start">
          <div className="w-100 float-left">
            <h1>Your Balance <br />${balance}</h1>
            <div className="lctn-dvs dashboard-main-buttons">
              <Link to={"/dashboard/amount-available"}>Get Cash</Link>
              <Link to="/dashboard/payment_choice">Add to Balance</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({}, dispatch);

const mapStateToProps = state => {
  return {
    token: state.token,
    profile: state.profile,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withRecoil(DashboardMain, [accessTokenState, nameState, phoneState])));
