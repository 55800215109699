import { combineReducers } from 'redux';
import {accessTokenReducer} from "./accessTokenReducer";
import {profileReducer} from "./profileReducer";
import {cashReqReducer} from "./cashReqReducer";


export default combineReducers({
  token: accessTokenReducer,
  profile: profileReducer,
  cashReqDto: cashReqReducer,
});
