import {SAVE_CASH_REQUEST_DTO, SAVE_PROFILE} from '../constant/index';

export const profileAction = (profile) => dispatch => {
  dispatch({
    type: SAVE_PROFILE,
    payload: profile
  });
  return Promise.resolve();
};


export const saveCashRequestDto = (cashReqDto) => dispatch => {
  dispatch({
    type: SAVE_CASH_REQUEST_DTO,
    payload: cashReqDto
  });
  return Promise.resolve();
};

