import React from "react";
import {Button} from "antd";
import {bindActionCreators} from "redux";
import {logout} from "../redux-stuffs/actions/token_action";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Link} from "react-router-dom";
import api from "../api/api";


class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            profilePic: '',
        }
    }

    toggleDropdown = () => {
        this.setState({isOpen: !this.state.isOpen});
    };

    componentDidMount() {
        if(!this.props.token) {
            this.props.history.push({
                pathname: '/'
            })
        } else {
        this.fetchProfilePic();
        //this.profilePicInterval = setInterval(this.fetchProfilePic, 60000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.profilePicInterval);
    }

    logout = () => {
        this.props.logout()
            .then(_ => {
                this.props.history.push({
                    pathname: '/login'
                })
            })
    };

    fetchProfilePic = () => {
        let body = {
            access_token: this.props.token,
            device_type: 3,
            device_token: 1,
            app_version: 1
        }
        api.accessTokenLogin(body)
        .then(response => {
            //console.log("profile pic: ", response.data.profile_pic)

            const isValidUrl = (string) => {
                try {
                  new URL(string);
                  return true;
                } catch (_) {
                  return false;  
                }
              };
              
              if (typeof response.data.profile_pic === 'string' && isValidUrl(response.data.profile_pic)) {
                this.setState({profilePic: response.data.profile_pic});
              } else {
                //console.error('Invalid profile picture URL:', response.data.profile_pic);
                this.setState({profilePic: "https://spare-app.s3.us-west-1.amazonaws.com/images/default_profile_pic.png"});
              }
        })
    }

    render() {

        return (
            <div className="navbar">
                <button className="profile-pic" aria-haspopup="menu" style={{border: 'none'}} onClick={this.toggleDropdown}>
                    <img src={this.state.profilePic} alt="Profile" />
                    {/* <img src={!profilePic ? defaultProfilePic : profilePic} alt="Profile" /> */}
                </button>
                    <div className={`dropdown-menu ${this.state.isOpen ? 'active' : ''}`} role="menu">
                        <ul>
                        <li><a href="/dashboard">Dashboard</a></li>
                        <li><a href="/profile">Profile</a></li>
                        {/* <li><a href="/security">Security</a></li> */}
                        {/* <li><a href="/communication">Communication</a></li> */}
                        <li><a href="/help">Help & FAQ</a></li>
                        {/* <li><a href="/promotions">Promotions</a></li> */}
                        <button onClick={this.logout} className="logout-btn">Logout</button>
                        </ul>
                    </div>
                    </div>
            );
        }
}


const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            logout: logout,
        },
        dispatch,
    );
const mapStateToProps = state => {
    return {
        token: state.token,
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Header));
