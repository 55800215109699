import React from "react";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

class DashboardMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}

    }

    render() {


        return (
            <div className="container mt-4">
                <div className="spare-dvs vh-100 align-items-start">
                    <div className="w-100 float-left">
                        <h1>Add to Balance</h1>
                        <div className="lctn-dvs dashboard-main-buttons">
                            <Link to='/card-details'>Debit Card</Link>
                            <Link to={"/dashboard/payment"}>Credit Card</Link>
                            <Link to="/dashboard/payment-check">Check</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
        },
        dispatch,
    );
const mapStateToProps = state => {
    return {
        token: state.token,
        profile: state.profile
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(DashboardMain));

