import React from "react";

class CashReady extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="container mt-4">
                <div className="cash-dvs vh-100 align-items-start">
                    <div className="w-100 float-left">
                        <h1>Cash Ready for Pick-up</h1>
                        <p>Go to: <br/> Address: (press to copy/Open with Google/Open with Maps)</p>

                        <div className="cshrdy-dv">
                            <form action="">
                                <p>
                                    <input type="text" name="" id="" className="csh-input"
                                            placeholder="Give this number to store: "/>
                                </p>

                                <p>
                                    <input type="number" inputMode='numeric' pattern="[0-9]*" name="" id="" className="csh-input"
                                            placeholder="PIN:"/>
                                </p>

                                <div className="btn-cash">
                                    <input type="submit" value="Cancel" name=""/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
)
}
}

export default CashReady;
