import React from "react";
import {Link} from "react-router-dom";

class Landing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="container mt-4">
                <div className="spare-dvs vh-100 align-items-start">
                    <div className="w-100 float-left">
                    <div style={{ padding: '30px' }}>
                        <img
                            src={process.env.PUBLIC_URL + "/images/spare_logo_full.png"}
                            alt="Spare Logo"
                            className="responsive-image"
                            style={{maxWidth:'30%'}}
                        />
                    </div>
                        <h1>Spare Virtual ATM</h1>
                        <div className="log-form">
                            <Link to="/login" className="log-btn">Get Cash</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Landing;
