export function capitalizeFirstLetter(word) {
    if (typeof word !== 'string') return '';
    return word.charAt(0).toUpperCase() + word.slice(1).toLocaleLowerCase();
}

export function isValidPhoneNumber(rule, value, callback) {

    const phoneNumberREgeso = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    if (value.match(phoneNumberREgeso)) {
        callback();
    } else {
        callback("Please enter a valid number")
    }
}

export function defaultFormValues () {
    return {
        device_type: -1,
        device_token: 0,
        app_version: 0,
    }
}
