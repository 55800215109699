import React from "react";
import {Link} from "react-router-dom";

class Complete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkmark: "https://spare-cdn.s3.us-west-1.amazonaws.com/images/checkmark.png"
        }
    }

    render() {
        return (
            <div className="container mt-4">
                <div className="spare-dvs vh-100 align-items-start">
                    <div className="w-100 float-left">
                        <h1>Transaction Complete</h1>
                        <img style= {{ width: '20%' }}src={this.state.checkmark} alt="checkmark" />
                        <br></br>
                        <br></br>
                        <h3> Please take cash from merchant. Thank you! </h3>
                        <div className="lctn-dvs dashboard-main-buttons">
                            <Link to={"/dashboard"}>Return to Dashboard</Link>
                            {/* <Link to="/profile">Profile</Link>
                            <Link to="/help">Help & FAQ</Link> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Complete;
