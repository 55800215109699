


export const TEST_ACTION = 'TEST_ACTION';
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const TEMP_ACCESS_TOKEN = 'TEMP_ACCESS_TOKEN';
export const SAVE_PROFILE = 'SAVE_PROFILE';
export const SAVE_CASH_REQUEST_DTO = 'SAVE_CASH_REQUEST_DTO';
export const SAVE_USERNAME = 'SAVE_USERNAME';
export const CHANGE_THEME = 'CHANGE_THEME';
export const LOGOUT = 'LOGOUT';
