import 'antd/dist/antd.css'
import 'react-phone-input-2/lib/style.css';
import "antd/dist/antd.less"; // import official less entry file
import "./custom-antd.less"; // override variables here
import "./override-css.css"; // override variables here
import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import './App.css';
import {hot} from 'react-hot-loader';
import NotFound from "./container/NotFound";
import SignupComponent from "./container/SignupComponent";
import EnterOtp from "./container/EnterOtp";
import SignupVerify from "./container/SignupVerify";
import Spare from "./container/Spare";
import Landing from "./container/Landing";
import Dashboard from "./container/Dashboard";
import LoginComponent from "./container/LoginComponent";
import Profile from "./container/Profile"
import Security from "./container/Security"
import Communication from "./container/Communication"
import Help from "./container/Help"
import Promotions from "./container/Promotions"
import EditProfile from "./container/EditProfile"
import CardDetails from "./container/CardDetails"
import FAQ from "./container/FAQ"
import History from "./container/History"
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { RecoilRoot } from 'recoil';


class App extends Component {

    render() {
        return (
            <RecoilRoot>
            <BrowserRouter>
            <div className="App">
                <Switch>
                    <Route exact path='/' component={Landing}/>
                    <Route exact path='/login' component={LoginComponent}/>
                    <Route exact path='/signup' component={SignupComponent}/>
                    <Route exact path='/enter-otp' component={EnterOtp}/>
                    <Route exact path='/signup-verify' component={SignupVerify}/>
                    <Route exact path='/spare' component={Spare}/>
                    <Route path='/dashboard' component={Dashboard}/>
                    <Route path='/profile' component={Profile}/>
                    <Route path='/edit-profile' component={EditProfile}/>
                    <Route path='/card-details' component={CardDetails}/>
                    <Route path='/history' component={History}/>
                    <Route path='/security' component={Security}/>
                    <Route path='/communication' component={Communication}/>
                    <Route path='/help' component={Help}/>
                    <Route path='/faq' component={FAQ}/>
                    <Route path='/promotions' component={Promotions}/>
                    <Route exact path='*' component={NotFound}/>
                </Switch>
            </div>
            </BrowserRouter>
            </RecoilRoot>
        );
    }
}

export default hot(module)(App);
