import 'antd/dist/antd.css'
import 'react-phone-input-2/lib/style.css';
import "antd/dist/antd.less";
import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import AmountAvailable from "../container/AmountAvailable";
import AmountAvailable2 from "../container/AmountAvailable2";
import CashReady from "../container/CashReady";
import Complete from "../container/Complete";
import Network from "../container/Network";
import DashboardMain from "../container/DashboardMain";
import Payment from "../container/Payment";
import PaymentCheck from "../container/PaymentCheck";
import PaymentChoice from "../container/PaymentChoice";
import CardDetails from "../container/CardDetails"
import Pickup from "./Pickup";
import Header from "../components/Header";

class Dashboard extends Component {


    render() {
        return (
            <div className="App">

                <Header/>

                <Switch>
                    <Route exact path='/dashboard' component={DashboardMain}/>
                    <Route exact path='/dashboard/amount-available' component={AmountAvailable}/>
                    <Route exact path='/dashboard/amount-available2' component={AmountAvailable2}/>
                    <Route exact path='/dashboard/cash-ready' component={CashReady}/>
                    <Route exact path='/dashboard/complete' component={Complete}/>
                    <Route exact path='/dashboard/network' component={Network}/>
                    <Route exact path='/dashboard/payment_choice' component={PaymentChoice}/>
                    <Route path='/dashboard/card-details' component={CardDetails}/>
                    <Route exact path='/dashboard/payment' component={Payment}/>
                    <Route exact path='/dashboard/payment-check' component={PaymentCheck}/>
                    <Route exact path='/dashboard/pickup' component={Pickup}/>
                    <Redirect to={"/dashboard"} exact path='*'/>
                </Switch>
            </div>
        );
    }
}

export default Dashboard;
