import {SAVE_PROFILE} from '../constant';

export const profileReducer = (state = null, action) => {
    switch (action.type) {
        case SAVE_PROFILE:
            return action.payload;
        default:
            return state;
    }
};
