import React from "react";
import {bindActionCreators} from "redux";
import {tokenAction} from "../redux-stuffs/actions/token_action";
import {connect} from "react-redux";
//import {withRouter} from "react-router-dom";
import {withRouter} from "react-router";
import {Checkbox, Col, Form, Input, Modal, Row} from "antd";
import moment from 'moment';
import api from "../api/api";

class Payment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expYears: [],
            value: 5,
            finalDto: {
                firstname: "John",
                lastname: "Doe",
                amount: 20,
            },
            tacAgreed: false,
            modalVisible: false,
            apiLoading: false,
        }
    }

    componentDidMount() {
        let currentYear = (new Date()).getFullYear();
        let years = [];
        for (let i = currentYear; i < currentYear + 20; i++) {
            years.push(i);
        }

        this.setState({
            expYears: years
        }, () => {
        });
    }

    handleFormSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {email} = this.props.profile;
                const {total,fee} = this.state;
                values.source_name = "creditcard";
                values.email = email;
                values.total = total;
                values.fee = fee;
                this.setState({
                    finalDto: values
                }, () => {
                    this.setState({
                        modalVisible: true
                    });
                });
            }
        });
    };

    onChange = e => {
        this.setState({
            value: e.target.value,
        });
    };

    handleOk = () => {
        const {finalDto} = this.state;
        finalDto.amount = finalDto.value;

        this.setState({
            apiLoading: true
        }, () => {
            const thisVar = this;
            api.updateEscrowAmount(finalDto)
                .then(res => {
                    if (res.status !== 105) {
                        return Modal.error({
                            title: "Error",
                            content: res.message,
                            onOk: () => {
                                thisVar.setState({
                                    apiLoading: false,
                                    modalVisible: false,
                                });
                            }
                        })
                    } else {
                        Modal.success({
                            title: "Success",
                            content: "Successfully updated escrow amount",
                            onOk: () => {
                                thisVar.setState({
                                    apiLoading: false,
                                    modalVisible: false,
                                }, () => {
                                    thisVar.props.history.push({
                                        pathname: '/dashboard'
                                    })
                                });
                            }
                        })
                    }
                })
                .catch(err => {
                    this.setState({
                        apiLoading: false,
                    });
                })
        });
    };

    amountChange = (e) => {

        // 3% + $0.30

        let amount = e.target.value;

        if(amount < 0){
            amount = Math.abs(amount);
        }

        let fee = (amount * .03) + .3;
        fee = fee.toFixed(2);

        let total = Number(amount) + Number(fee);
        total = total.toFixed(2);

        this.setState({
            amount: amount,
            fee: fee,
            total: total
        });

        console.log(this.state);
    };

    render() {

        const {getFieldDecorator} = this.props.form;
        const {expYears, finalDto, modalVisible, tacAgreed, apiLoading} = this.state;

        return (
            <div className="full-desk w-100 float-left d-inline-block">
                <div className="container mg-btm-100 mg-top-50">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="spare-dvs align-items-start">
                                <div className="w-100 float-left">
                                    <h2>Credit Card Transfer </h2>

                                    <div className="row">
                                        <div className="col-lg-6 offset-lg-3">


                                            <div className="accpt-dvs">
                                                <p>We Accept</p>
                                                <img src={require("../assets/images/paymnt.png")}
                                                     alt="Payment"/>
                                            </div>

                                            <div className="card-dtls">
                                                {/* <h3>Please enter your card details below</h3> */}

                                                <Form onSubmit={this.handleFormSubmit}>

                                                    <div className="row">
                                                        <Form.Item className="col-lg-6">
                                                            {getFieldDecorator('firstname', {
                                                                rules: [{
                                                                    required: true,
                                                                    message: 'Please input your first name.'
                                                                }],
                                                            })(
                                                                <p>
                                                                    <label>First Name</label>
                                                                    <Input type="text" placeholder="Jane"/>
                                                                </p>
                                                            )}
                                                        </Form.Item>
                                                        <Form.Item className="col-lg-6">
                                                            {getFieldDecorator('lastname', {
                                                                rules: [{
                                                                    required: true,
                                                                    message: 'Please input your last name.'
                                                                }],
                                                            })(
                                                                <p>
                                                                    <label>Last Name</label>
                                                                    <Input type="text" placeholder="Doe"/>
                                                                </p>
                                                            )}
                                                        </Form.Item>
                                                    </div>

                                                    <div className="row">
                                                        <Form.Item className="col-lg-12">
                                                            {getFieldDecorator('cc_number', {
                                                                rules: [{
                                                                    required: true,
                                                                    message: 'Please input a valid 12 digit credit card number.'
                                                                },
                                                                    {
                                                                        validator: (rule, value, callback) => {
                                                                            if (value.length != 12) {
                                                                                callback("Please enter 12 digits.")
                                                                            } else {
                                                                                callback();
                                                                            }
                                                                        }
                                                                    }],
                                                            })(
                                                                <p>
                                                                    <label>Card Number</label>
                                                                    <Input type="number" inputMode='numeric' pattern="[0-9]*" name="" id="" placeholder="12 digit number"/>
                                                                </p>
                                                            )}
                                                        </Form.Item>
                                                    </div>


                                                    <div className="row">
                                                        <Form.Item className="col-lg-4 col-6 col-sm-4">
                                                            {getFieldDecorator('expr_month', {
                                                                rules: [{
                                                                    required: true,
                                                                    message: 'Please input the expiry month.'
                                                                }],
                                                                initialValue: 1
                                                            })(
                                                                <p>
                                                                    <label>Expiration Date</label>
                                                                    <select>
                                                                        {[ "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"].map((item, i) => (
                                                                            <option value={item} key={i}>{item}</option>
                                                                        ))}
                                                                    </select>
                                                                </p>
                                                            )}
                                                        </Form.Item>
                                                        <Form.Item className="col-lg-4 col-6 col-sm-4">
                                                            {getFieldDecorator('expr_year', {
                                                                rules: [{
                                                                    required: true,
                                                                    message: 'Please input the expiry year.'
                                                                }],
                                                                initialValue: (new Date()).getFullYear()
                                                            })(
                                                                <p>
                                                                    <label> &nbsp; </label>
                                                                    <select>
                                                                        {expYears.map((item, i) => (
                                                                            <option value={item} key={i}>{item}</option>
                                                                        ))}
                                                                    </select>
                                                                </p>
                                                            )}
                                                        </Form.Item>
                                                        <Form.Item className="col-12">
                                                            {getFieldDecorator('cvv', {
                                                                rules: [{
                                                                    required: true,
                                                                    message: 'Please input the CVV number.'
                                                                },{
                                                                    validator: (rule, value, callback) => {
                                                                        if (value.length != 3) {
                                                                            callback("Please enter 3 digits.")
                                                                        } else {
                                                                            callback();
                                                                        }
                                                                    }
                                                                }],
                                                            })(
                                                                <p>
                                                                    <label> CVV </label>
                                                                    <Input type="number" inputMode='numeric' pattern="[0-9]*" placeholder="3 digit number"/>
                                                                </p>
                                                            )}
                                                        </Form.Item>
                                                        <Form.Item className="col-12">
                                                            {getFieldDecorator('zip', {
                                                                rules: [{
                                                                    required: true,
                                                                    message: 'Please input the zip code.'
                                                                }],
                                                            })(
                                                                <p>
                                                                    <label> Billing Zip Code </label>
                                                                    <Input type="number" inputMode='numeric' pattern="[0-9]*" placeholder="ex. 91601"/>
                                                                </p>
                                                            )}
                                                        </Form.Item>

                                                        <Form.Item className="col-12">
                                                            {getFieldDecorator('termsAndConditions', {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: ' '
                                                                    },
                                                                    {
                                                                        validator: (rule, value, callback) => {
                                                                            if (value) {
                                                                                callback();
                                                                            } else {
                                                                                callback('Please review the terms and policies.');
                                                                            }
                                                                        }
                                                                    }
                                                                ],
                                                            })(
                                                            <div>
                                                                <br></br>
                                                                <div style={{display: 'flex', justifyContent: 'in-between'}}>
                                                                    <div style={{flex: '1'}}>
                                                                        <input type="checkbox"/>
                                                                    </div>
                                                                    <div style={{flex: '100'}}>
                                                                        <span> I authorize the transaction on this date to
                                                                        SPARE in the amount shown above. I acknowledge
                                                                        having received all the goods/services related
                                                                        to or associated with this transaction, and
                                                                        furthermore agree not to dispute any amount or
                                                                        other aspect of this transaction at any time.
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <br></br>


                                                <div className="row radio-amount-input mg-btm-50">
                                                <div style={{backgroundColor: '#DBE2FE', margin: 'auto', padding: '20px', borderRadius: '20px'}}>
                                                <div className="amount-dtls">
                                                    <div className="" >

                                                    <p style={{fontSize: '18px'}}>Payment details</p>
                                                        <Form.Item>
                                                            {getFieldDecorator('value', {
                                                                rules: [{
                                                                    required: true,
                                                                    message: 'Please input the amount!'
                                                                }],
                                                            })(

                                                                <p>
                                                                    <div className="payment-box">
                                                                    <label >Deposit amount</label>
                                                                    <Input type="text" id="amount" placeholder="0.00" style={{padding: '10px'}} onChange={this.amountChange} value={this.state.amount} min={0} />
                                                                    </div>
                                                                </p>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <div className="readonly-input">
                                                        <Form.Item>
                                                            {getFieldDecorator('fee', {
                                                            })(
                                                                <p>
                                                                    <div className="payment-box">
                                                                    <label>Transaction Fee</label>
                                                                    <Input type="number" className="readonly-input" style={{padding: '10px'}} value={this.state.fee} id="fee" readOnly />
                                                                    </div>
                                                                </p>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                    <div className="">
                                                        <Form.Item>
                                                            {getFieldDecorator('total', {
                                                            })(
                                                                <p>
                                                                    <div className="payment-box">
                                                                    <label>Total</label>
                                                                    <Input type="number" className="readonly-input" style={{padding: '10px'}} value={this.state.total} id="total" readOnly />
                                                                    </div>
                                                                </p>
                                                            )}
                                                        </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                                    <div className="row" >
                                                        <div className="col-lg-12" >
                                                            <input type="submit" name=""  value="Fund" style={{marginTop: '0px'}}
                                                                   className="fund-bt"/>
                                                        </div>
                                                    </div>
                                                </Form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Modal
                    title={null}
                    visible={modalVisible}
                    onOk={this.handleOk}
                    onCancel={() => {
                        this.setState({
                            modalVisible: false
                        });
                    }}
                    okButtonProps={{
                        disabled: !tacAgreed,
                        loading: apiLoading,
                        style: {
                            backgroundColor: "#EB4D31"
                        }
                    }}
                >
                    <p className="mg-top-50 text-center-align">
                        I, {finalDto.firstname} {finalDto.lastname}, irrevocably authorize the
                        transaction dated {moment(new Date()).format("MM/DD/YYYY")} to SPARE in the amount of
                        ${finalDto.total}. I acknowledge having received all the
                        goods/services related to or associated with this transaction,
                        and furthermore agree not to dispute any amount or other
                        aspect of this transaction at any time.
                    </p>
                    <Row>
                        <Col span={24} className={"all-center"}>
                            <Checkbox
                                value={tacAgreed}
                                onChange={(e) => {
                                    this.setState({
                                        tacAgreed: e.target.checked
                                    });
                                }}>I Agree</Checkbox>
                        </Col>
                    </Row>
                </Modal>


            </div>
        )
    }
}

const WrappedPaymentForm = Form.create({name: 'payment_form'})(Payment);

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            tokenAction: tokenAction
        },
        dispatch,
    );
const mapStateToProps = state => {
    return {
        token: state.token,
        profile: state.profile
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(WrappedPaymentForm));
