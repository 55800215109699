import React from "react";
import {QrReader} from 'react-qr-reader';
import {Icon, Modal, Input, Spin} from "antd";
import {bindActionCreators} from "redux";
import {tokenAction} from "../redux-stuffs/actions/token_action";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {saveCashRequestDto} from "../redux-stuffs/actions/profile_action";
import { useRecoilState } from "recoil";
import {merchantAddressState, nameState, phoneState, amountState} from '../atoms';
import withRecoil from "../withRecoil";
import Barcode from "./Barcode";
import api from "../api/api"

const TEN_MINUTES = 10 * 60;

class Pickup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isApiLoading: false,
            // setIntervalFunction: null,
            result: null,
            pin: '',
            isPinModalOpen: false,
            isScanning: false,
            errorMessage: '',
            isMerchantOpen: false,
            isSuccess: false,
            completeReq: false,
            isQROpen: false,
            showText: true,
            isBarcodeOpen: false,
            barcodeData: [],
            createdAt: null,
        }
    }

    startScan = (e) => {
        e.preventDefault();
    }

    // scans the qr code and updates result to contain data
    handleScan = (data, error) => {
        if (data) {
            this.setState({ result: data.text, isScanning: false });
            console.log(this.state.result);
            this.openPinModal();
        }
        if (error) {
            console.info(error);
        }
    };

    //cash request id, phone, pin, name, amount, expiration time
    handleBarcodeData = () => {
        const createdAtDate = new Date(this.state.createdAt);
        console.log("Before:", createdAtDate);
        createdAtDate.setSeconds(createdAtDate.getSeconds() + 600);
        const expiration = createdAtDate.toISOString();
        console.log("After:", expiration);
        const barcodeData = {
            cash_request_id: this.props.cashReqDto.cash_request_id,
            pin: this.props.cashReqDto.pin,
            name: this.props.recoilStates.nameState.state,
            phone: this.props.recoilStates.phoneState.state,
            amount: this.props.recoilStates.amountState.state,
            expiration: expiration
        };

        this.setState({ barcodeData }, () => {
            console.log('Barcode Data:', this.state.barcodeData);
        });
    }


    handlePin = () => {
        const { pin, result } = this.state;
        this.setState({errorMessage: ''})

        this.sendPayload(pin, result)
        .then(responseData => {
            console.log("response data: ", responseData)
            console.log("response status: ", responseData.status)

            if(responseData.status === 104)
            {
                console.log("Success!")
                this.setState({
                    isSuccess: true,
                    errorMessage: '',
                    isPinModalOpen: false,
                })
                this.openMerchantSide();
            }else{
                console.log('Incorrect pin')
                this.setState({errorMessage: 'Incorrect Pin. Please try again', pin: ''})
            }
        })
        .catch(error=>{
            console.error("Error with pin: ", error)
            this.setState({errorMessage: 'Please try again.'})
        })
    }

    openPinModal = () => {
        this.setState({isPinModalOpen: true})
    }

    openMerchantSide = () => {
        this.setState({isMerchantOpen: true})
    }

    closePinModal = () => {
        this.setState(prevState => ({
            isPinModalOpen: false,
            result: prevState.isMerchantOpen ? null: prevState.result,
            pin: '',
            isScanning: !prevState.isMerchantOpen,
        }))
    }

    closeMerchantSide = () => {
        this.setState({isMerchantOpen: false})
    }

    handlePinChange = (e) => {
        this.setState({pin: e.target.value})
    }

    // sends the qr id, pin and access token to the backend for verification
    sendPayload = (pin, result) => {
        return new Promise((resolve, reject) => {
            const apiUrl = `${api}/ccm/verify_request/qr`;
            // parses result of qr scan to find id
            const parsed = JSON.parse(result);
            const qr_uuid = parsed.qr_uuid;

            console.log("qr_uuid: ", qr_uuid);
            console.log("pin: ", pin);
            console.log("access token: ", this.props.token);

            fetch(apiUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                access_token: this.props.token,
                qr_uuid: qr_uuid,
                pin: pin,
              }),
            })
              .then(response => response.json())
              .then(responseData => {
                //console.log("response data: ", responseData);
                resolve(responseData);
              })
              .catch(error => {
                console.log(error.message);
                reject(error.message);
              });
          });
    }

    // is called repeatedly by polling interval
    // checks for the status of the transaction on the merchant side
    // once completed or canceled ends repeat with complete request
    requestStatus = () =>{
        const apiUrl = `${api}/cash_request_status`;
        const cashRequest = this.props.cashReqDto.cash_request_id;

        fetch(apiUrl, {
            method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                access_token: this.props.token,
                cash_request_id: cashRequest
              }),
        })
        .then(response => response.json())
        .then(responseData => {
            console.log("Checking Status", responseData)
            this.setState({createdAt: responseData.data.created_at})
            console.log("date set: ", this.state.createdAt)
            if(responseData.status == "403" )
            {
                console.log('timed out or failed')
                // cancel the api call and return an error to the user
                // close polling interval
                this.callCancelApi()
                clearInterval(this.pollingInterval);
                this.closeMerchantSide()
                Modal.confirm({
                    title: "Failed Withdrawal",
                    content: responseData.message,
                    onOk: this.props.history.push("/dashboard/amount-available")
                })
            }
            else if(responseData.data.status == "Complete"){
                console.log('complete')
                // complete request
                fetch(`${api}/ccm/complete_request`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        access_token: this.props.token,
                        request_id: cashRequest,
                        status: 'Complete'
                    }),
                })
                this.props.history.push({
                    pathname: '/dashboard/complete'
                })
                clearInterval(this.pollingInterval);
                this.closeMerchantSide()
            }
        })
        .catch(error => {
            console.log(error.message);
        });

    }

    renderPinModal = () => {
        const { isPinModalOpen, pin, errorMessage } = this.state;
        return(
        <Modal
            title="Enter Pin"
            visible={isPinModalOpen}
            onOk={this.handlePin}
            onCancel={this.closePinModal}
            width={window.innerWidth < 576 ? '100%' : 520}
            >
                <div>
                <p>Enter Pin number: </p>
                <Input type="password" value={pin} onChange={this.handlePinChange} />
                <p style={{ color: 'red' }}>{errorMessage}</p>
                </div>
        </Modal>
        )
    }

    renderMerchant = () => {
        const { isMerchantOpen, isSuccess} = this.state;
        return (
            <Modal
                title="Success"
                visible={isMerchantOpen}
                width={window.innerWidth < 576 ? '100%' : 520}
                onCancel={this.cancelCashReq}
                footer={[
                    <button key="submit" type="primary" onClick={this.cancelCashReq} style={{border: "1px solid red", fontSize: "15px", color: "red", backgroundColor: "white"}}>
                        Cancel Request
                    </button>
                ]}
                >
                    {isSuccess && (
                    <div>
                        <p>Please wait for merchant to confirm. </p>
                    </div>
                    )}
            </Modal>
        )
    }

    cancelCashReq = () => {
        const thisVar = this;
        Modal.confirm({
            title: "Are you sure?",
            content: "Cash Request cannot be recovered once cancelled.",
            onOk: thisVar.callCancelApi
        })
    };

    componentDidMount() {
        this.requestStatus()
        this.pollingInterval = setInterval(this.requestStatus, 3000)
    }

    componentWillUnmount() {
        if (this.pollingInterval) {
            clearInterval(this.pollingInterval);
        }
    }

    callCancelApi = () => {
        const {cashReqDto} = this.props;
        this.setState({
            isApiLoading: true
        }, () => {
            let body = {
                cash_request_id: cashReqDto.cash_request_id,
                action: 0
            };
            api.cancelCashRequest(body)
                .then(res => {
                    this.setState({
                        isApiLoading: false
                    });
                    this.props.history.push({
                        pathname: '/dashboard/amount-available'
                    })
                })
                .catch(err => {
                    this.setState({
                        isApiLoading: false
                    });
                })
        });

    };

    handleScanQRCode = () => {
        this.setState({isScanning: true})
        this.setState({isQROpen: true})
        this.setState({showText: false})
        this.setState({isBarcodeOpen: false})
        console.log("handle scan")
    }

    handleShowText = (e) => {
        e.preventDefault()
        this.setState({isScanning: false})
        this.setState({isQROpen: false})
        this.setState({showText: true})
        this.setState({isBarcodeOpen: false})
    }

    handleBarcode = (e) => {
        e.preventDefault()
        this.handleBarcodeData();
        console.log(this.props.cashReqDto)

        this.setState({isScanning: false})
        this.setState({isQROpen: false})
        this.setState({showText: false})
        this.setState({isBarcodeOpen: true})
    }

    render() {
        const {cashReqDto} = this.props;
        const {isApiLoading, isQROpen, showText, barcodeData, isBarcodeOpen } = this.state;

        return (
            <div className="container mt-4">
                <div className="spare-dvs vh-100 align-items-start">
                    <div className="w-100 float-left">

                        <h2>Request Sent to</h2>
                        <h3>{this.props.recoilStates.merchantAddressState.state}</h3>
                        <Spin
                            spinning={isApiLoading}
                            indicator={<Icon type="loading" style={{fontSize: 50, color: "#94220d"}}/>}
                        >

                            {/* <br></br> */}
                                <h3 style={{padding: "2px"}}>Please verify transaction</h3>
                            <div className='verify-btn'>
                                <div className="pickup-footer">
                                    <button onClick={this.handleScanQRCode}className="function-btns" >
                                        <img src="/images/qr.png" className="img-btns"  alt="Scan QR Code" />
                                    </button>
                                    <button onClick={this.handleBarcode}
                                    className="function-btns">
                                        <img src="/images/barcode.png" className="img-btns"  alt="Scan Barcode" />
                                    </button>
                                    <button onClick={this.handleShowText} className="function-btns" >
                                        <img src="/images/phone.png" className="img-btns" alt="Phone Number" />
                                    </button>
                                </div>
                                </div>
                                {isQROpen && (
                                    <div style={{ display: "flex", justifyContent: "center", marginBottom: "0"}}>
                                        <div className="QR-btn" >
                                        {this.startScan}
                                        <QrReader
                                        onResult={this.handleScan}
                                        style={{ width: "100%", height: "auto" }}
                                        showViewFinder={true}
                                        facingMode="environment"
                                        className="verify-btn"/>
                                        <div className="pin-btn">
                                            <p>Pin: {cashReqDto.pin}</p>
                                        </div>
                                        {this.renderPinModal()}
                                        {this.renderMerchant()}
                                        </div>
                                        </div>
                                )}

                                {isBarcodeOpen && (
                                    <div style={{marginTop: "30px"}}>
                                        <Barcode value={JSON.stringify(barcodeData)}/>
                                    </div>
                                )}
                                <div>
                                    <br></br>
                                    <div className="verify-btn">
                                    {showText ? (
                                    <div style={{paddingBottom: "20px"}}>
                                    <div className="content-box">
                                        Provide your phone number to the cashier along with the pin below.
                                        <br>
                                        </br>
                                        <br>
                                        </br>
                                        Pin: {cashReqDto.pin}
                                    </div>
                                    </div>
                                    ): null}
                                </div>
                                </div>
                        </Spin>
                        <a href="#" onClick={this.cancelCashReq} className="cancel-button">
                                        Cancel
                        </a>
                </div>
            </div>
        </div>
        )
    }
}

const mapDispatchToProps = (dispatch) =>

    bindActionCreators(
      {
        tokenAction,
        saveCashRequestDto,
      },
      dispatch
    );

  const mapStateToProps = (state) => {
    return {
      token: state.token,
      profile: state.profile,
      cashReqDto: state.cashReqDto,
    };
  };

  export default connect(mapStateToProps, mapDispatchToProps)(
    withRouter(withRecoil(Pickup, [merchantAddressState, nameState, phoneState, amountState]))
  );
