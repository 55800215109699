import React from 'react';
import {bindActionCreators} from "redux";
import {tokenAction} from "../redux-stuffs/actions/token_action";
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";
import {Modal} from "antd";
import Header from "../components/Header";
import api from "../api/api"

class EditProfile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            profilePic: '',
            oldPassword: '',
            newPassword: '',
            preview: ''
        };
    }

    handleProfilePicChange = (e) => {
        e.preventDefault();
        const newPic = e.target.files[0]
        this.setState({ profilePic: newPic}, () => {
            console.log("Printing from handleProfilePicChange function: ", this.state.profilePic);
            if (newPic) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    this.setState({ preview: reader.result });
                };
                reader.readAsDataURL(newPic);
            } else {
                this.setState({ preview: '' });
            }
        });
    }

    saveProfilePicChange = () => {

        if(this.state.profilePic !== ''){
            const formData = new FormData();
            formData.append('access_token', this.props.token);
            formData.append('name', this.props.name);
            formData.append('image', this.state.profilePic);

            const profileUpdate = `${api}/update_user_profile`;
            fetch(profileUpdate, {
                method: 'POST',
                body: formData,
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log("Response data pic: ", data);
                if(data.status === 105){
                    this.setState({profilePic: '', preview: ''});
                    Modal.success({
                        title: 'Success',
                        content: "Profile Pic has been successfully updated!",
                        centered: true,
                        onOk: () => {window.location.reload()}
                    });
                }
                else{
                    throw new Error(data.message)
                }
            })
            .catch(error => {
                console.log("Error with profile pic: ", error.message);
                Modal.error({
                    title: "Error",
                    content: error.message,
                    onOk: () => {this.setState({profilePic: '', preview: ''})}
                })
            });
        }
        this.setState({profilePic: '', preview: ''});
    }

    savePasswordChange = () => {
        if(this.state.oldPassword !== '' && this.state.newPassword !== ''){
            if(this.state.newPassword.length < 8)
            {
                Modal.error({
                    title: "Error",
                    content: "New password must be at least 8 characters long.",
                    onOk: () => {window.location.reload()}
                })
                return;
            }
            const passwordUpdate = `${api}/reset_password`;
            fetch(passwordUpdate, {
                method: 'POST',
                body: JSON.stringify ({
                    access_token: this.props.token,
                    password: this.state.newPassword,
                    old_password: this.state.oldPassword
                }),
                headers: {
                    "Content-Type": 'application/json'
                }
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Response password:', data);
                if(data.status === 104){
                    this.showRedirectModal()
                }
                else{
                    throw new Error(data.message)
                }
            })
            .catch(error => {
                console.log("Error with password: ", error.message);
                Modal.error({
                    title: "Error",
                    content: error.message,
                    onOk: () => {window.location.reload()}
                })
            });
        }
        this.setState({ oldPassword: '', newPassword: ''});
    }

    showRedirectModal = () => {
        let countdown = 5;
        const modal = Modal.info({
            title: 'Password Updated Successfully',
            content: `You will be redirected to the login page in ${countdown} seconds.`,
            centered: true,
            okButtonProps: { style: { display: 'none' } },
        });
    
        const countdownInterval = setInterval(() => {
            countdown--;
            modal.update({
                content: `You will be redirected to the login page in ${countdown} seconds.`,
            });
            if (countdown === 0) {
                clearInterval(countdownInterval);
                modal.destroy();
                this.props.history.push({ pathname: '/login' });
            }
        }, 1000);
    }

    render() {
        const {preview} = this.state

        return (
            <div>
                <Header/>
                <div className="container mt-4">
                            <div className="spare-dvs vh-100 align-items-start">
                                <div className="w-100 float-left">
                                <h1>Edit Profile</h1>
                                <div>
                                <br  />
                                <p style={{fontSize: "25px"}}>Change Profile Pic</p>
                                {preview && (
                                        <div>
                                            <br />
                                            <img src={preview} className="preview-image" alt="Preview"/>
                                        </div>
                                    )}
                                <br  />
                                    <input type="file" accept="image/*" onChange={this.handleProfilePicChange} />
                                <div/>
                                <br></br>
                                <button type="submit" onClick={this.saveProfilePicChange}>Save</button>
                            </div>
                            <div>
                            <br  />
                            <hr></hr>
                            <br  />
                            <p style={{fontSize: "25px"}}>Change Password</p>
                            <br  />
                                <label htmlFor="old_password">Enter Old Password: </label>
                                <br  />
                                <input
                                type="password"
                                id="old_password" name="old_password"
                                onChange={(e) => {
                                    this.setState({ oldPassword: e.target.value });
                                }}
                                onBlur={(e) => {
                                    this.setState({ oldPassword: e.target.value });
                                    console.log("Old Password:", this.state.oldPassword);
                                }}
                                />
                            </div>
                            <div>
                                <label htmlFor="password">Enter New Password: </label>
                                <br  />
                                <input
                                type="password"
                                id="password" name="password"
                                onChange={(e) => {
                                    this.setState({ newPassword: e.target.value });
                                }}
                                onBlur={(e) => {
                                    this.setState({ newPassword: e.target.value });
                                    console.log("New Password:", this.state.newPassword);
                                }}
                                />
                            </div>
                            <br></br>
                            <button type="submit" onClick={this.savePasswordChange}>Save</button>
                            <Link to="/profile">Done</Link>
                            </div>
                            </div>
                </div>
            </div>
        );
    };
    }

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            tokenAction: tokenAction,
        },
        dispatch,
    );

const mapStateToProps = state => {
    return {
        token: state.token,
        profile: state.profile
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(EditProfile));
